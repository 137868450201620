import React, { FC, useState } from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { ILog, IProject } from '../../helper/types';
import CustomDataGrid from '../CustomDataGrid';
import Button from '../Buttons/Button';
import useAppData from '../../hooks/useAppData';

interface WarningListModalProps {
  visible: boolean;
  data: ILog[];
  onClear: () => void;
  onClose: () => void;
}

const WarningListModal: FC<WarningListModalProps> = props => {
  const {
    visible,
    data,
    onClear,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { isMobile } = useAppData();
  const columns = [
    {
      flex: 0.35,
      minWidth: 100,
      field: 'time',
      headerName: t('Monitor.List.Time'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light">{row.log_date}</span>
    },
    {
      flex: 0.2,
      minWidth: 110,
      field: 'title',
      headerName: t('Monitor.List.LC'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light">{row.lc_title || ''}</span>
    },
    {
      flex: 0.15,
      minWidth: 90,
      field: 'value',
      headerName: t('Monitor.List.Value'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light">{row.value || ''}</span>
    },
    {
      flex: 0.15,
      minWidth: 90,
      field: 'overload',
      headerName: t('Monitor.List.Overload'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light">{row.underload || ''}</span>
    },
    {
      flex: 0.15,
      minWidth: 90,
      field: 'underload',
      headerName: t('Monitor.List.Underload'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light">{row.overload || ''}</span>
    },
  ];

  return (
    <Modal
      visible={visible}
      header={t("Monitor.Modal.Warnings")}
      headerSub={
        <Button
          title={t('Common.Clear')}
          classes={`${isMobile ? "px-4 py-2" : "px-4 py-1"} bg-danger  rounded ml-10`}
          textClasses='text-white'
          onAction={() => onClear()}
        />
      }
      classes='w-max border border-secondary rounded-md'
      contentClasses={` !items-center  px-6 ${isMobile ? "!mt-14" : " py-2 pt-6"}`}
      footerClasses='!justify-center'
      cancelTitle={t('Common.Cancel')}
      onClose={() => onClose()}
    >
      <CustomDataGrid
        columns={columns}
        data={data}
      />
    </Modal>
  )
}

export default WarningListModal;
