import { DataGrid } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';

import './index.css';

interface CustomDataGridProps {
    classes?: string;
    loading?: boolean;
    // eslint-disable-next-line
    columns: any[];
    // eslint-disable-next-line
    data: any[];
    // eslint-disable-next-line
    onRowAction?: (row: any) => void;
}

const CustomDataGrid: FC<CustomDataGridProps> = props => {
    const {
        classes = '',
        loading = false,
        columns,
        data,
        // eslint-disable-next-line
        onRowAction = () => {}
    } = props;

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

    return (
        <DataGrid
            autoHeight
            loading={loading}
            rowHeight={54}
            rows={data}
            columns={columns}
            disableRowSelectionOnClick
            paginationModel={paginationModel}
            pageSizeOptions={[6, 10, 25, 50]}
            className={`px-4 cursor-pointer bg-transparent overflow-auto ${classes}`}
            onPaginationModelChange={setPaginationModel}
            onRowClick={e => onRowAction(e.row)}
            classes={{
                columnHeader: 'text-gray3',
                withBorderColor: '!border-gray-500',
                cellContent: '!text-white',
                overlay: '!bg-transparent text-gray3',
                footerContainer: '!border-none'
            }}
        />
    )
}

export default CustomDataGrid;
