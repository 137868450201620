import React, { DragEventHandler, FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Draggable, { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { Resizable, ResizableBox, ResizeCallbackData } from 'react-resizable';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { locate, locateOutline, lockClosedOutline, lockOpenOutline, logOutOutline } from "ionicons/icons";

import { ILC } from '../../helper/types';
import { IonButton, IonIcon } from '@ionic/react';
import { t } from 'i18next';
import useAppData from '../../hooks/useAppData';
import useFunctions from '../../hooks/useFunctions';
import { Line } from 'react-chartjs-2';
import { db } from '../../db';

interface SizeInfoType {
  width: number,
  height: number,
}
interface PosInfoType {
  x: number,
  y: number,
}

interface MonitorViewProps {
  data: ILC[],
  max: boolean;
  load: boolean;
  tare: boolean;
  onMoveLC: (lcItem: ILC) => void;
  onReset: (status: boolean) => void;
}

const MonitorView: FC<MonitorViewProps> = (props) => {
  // console.log(curProject.p_image);
  
  const {
    data,
    max,
    load,
    tare,
    // eslint-disable-next-line
    onMoveLC = () => { },
    // eslint-disable-next-line
    onReset = () => { }
  } = props;

  const { bleConnected, curProject, liveLC, updateCurProject, updateProjects, groups, lcs } = useAppData()
  const { f_update_project_image_size, f_update_project_image_position, f_reposition_stage, f_load_lcs } = useFunctions()

  const [list, setListData] = useState<ILC[]>([]);
  const [prevState, setPrevState] = useState<Partial<ILC>[]>([]);

  const [reset, setReset] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);

  const [labels, setLabels] = useState<string[]>([])
  const [graphData, setGraphData] = useState<any[]>([])

  const [width, setWidth] = useState(100)
  const [height, setHeight] = useState(100)
  const [sizeInfo, setSizeInfo] = useState<SizeInfoType>({
    width: curProject.p_image_w ?? 100, height: curProject.p_image_h ?? 100
  })
  const [posInfo, setPosInfo] = useState<PosInfoType>({
    x: curProject.p_image_l ?? 0, y: curProject.p_image_t ?? 0
  })
  const [triggered, setTriggered] = useState<boolean>(false)

  const rndRef = useRef<Rnd>(null)
  const flag = useRef<number>(0)

  useEffect(() => {
    if (liveLC && !triggered) {
      setTriggered(true)
      setTimeout(() => {
        setTriggered(false)
        if (liveLC) {
          setLabels(v => [...v, liveLC.time])
          setGraphData(v => v.map((item) => {
            if (item.id === liveLC.id)
              return {
                ...item,
                data: [...item.data, liveLC.value]
              }
            return item
          }))
        }
      }, 3000)
    }
  }, [liveLC])

  useEffect(() => {
    // (async () => {
    //   if (!curProject) return
    //   await db.lcs.where('project_id').equals(curProject.id)
    //     .modify({ value: 0 })
    //   f_load_lcs()
    // })()
  }, [])

  useEffect(() => {
    console.log('data info: ', list);
    if (list.length) {
      setGraphData(list.map((item) => ({
        id: item.id,
        label: item.title,
        data: []
      })))
    }
  }, [list.length])

  useEffect(() => {
    if (curProject.p_image_l !== undefined)
      flag.current++
  }, [curProject])
  useEffect(() => {
    if (flag.current !== 1) return
    const size: SizeInfoType = { width: curProject.p_image_w ?? 100, height: curProject.p_image_h ?? 100 }
    const pos: PosInfoType = { x: curProject.p_image_l ?? 0, y: curProject.p_image_t ?? 0 }

    setSizeInfo(size)
    setPosInfo(pos)

    rndRef.current?.updateSize(size)
    rndRef.current?.updatePosition(pos)

  }, [flag.current])

  useEffect(() => {
    setListData(data)
  }, [data])

  useEffect(() => {
    onReset(reset)
  }, [reset])

  const reposition_lc = (_e: Partial<DragEventHandler>, data: Partial<DraggableData>, index: number) => {
    const updatedItem: ILC = { ...list[index], view_x: data.x + '', view_y: data.y + '' }
    setListData(v => v.map((item, idx) => {
      if (idx === index) return updatedItem
      else return item
    }));
    onMoveLC(updatedItem)
  }

  const handlePos = () => {
    console.log('hahndlePos')
  }

  const handleImageResize = (_e: MouseEvent | TouchEvent, _dir: string, _elementRef: HTMLElement, _resizeDelta: ResizableDelta, _position: Position) => {
    console.log('--- onResizeStop ---')
    const ttt = {
      width: sizeInfo.width ?? 100,
      height: sizeInfo.height ?? 100,
      x: posInfo.x,
      y: posInfo.y,
    }
    console.log({ ttt, _resizeDelta })


    const size: SizeInfoType = {
      width: (sizeInfo.width ?? 100) + _resizeDelta.width,
      height: (sizeInfo.height ?? 100) + _resizeDelta.height
    }
    const pos: PosInfoType = {
      x: posInfo.x ?? 0,
      y: posInfo.y ?? 0,
    }
    if (_dir === 'left' || _dir === 'topLeft' || _dir === 'bottomLeft') {
      pos.x -= _resizeDelta.width
    }
    if (_dir === 'top' || _dir === 'topLeft' || _dir === 'topRight') {
      pos.y -= _resizeDelta.height
    }
    setSizeInfo(size)
    setPosInfo(pos)
    f_update_project_image_size(curProject.id, size.width, size.height)
    f_update_project_image_position(curProject.id, pos.y, pos.x)
  };
  const handleImagePosition = (_e: DraggableEvent, data: DraggableData) => {
    console.log('--- onDragStop ---', { data })
    const pos = {
      x: data.lastX,
      y: data.lastY,
    }
    setPosInfo(pos)
    f_update_project_image_position(curProject.id, pos.y, pos.x)
    console.log('updated: ', {
      position: {
        x: data.lastX,
        y: data.lastY,
      }
    })
  }
  const handleFrameResize = (_e: SyntheticEvent, _data: ResizeCallbackData) => {
    const { size } = _data
    f_reposition_stage(curProject.id, size.width, size.height)
  }

  const clear_lc_monitor = () => {
    console.log("===clear_lc_monitor===")
  }

  const draw_lc_monitor = () => {
    console.log("===draw_lc_monitor===")
  }

  const draw_monitor_background = () => {
    console.log("===draw_monitor_background===")
  }

  const random_rgba = () => {
    console.log("===random_rgba===")
  }

  const draw_chart_line = () => {
    console.log("===draw_chart_line===")
  }

  const draw_chart = () => {
    console.log("===draw_chart===")
  }

  const draw_monitor_background_size = () => {
    console.log("===draw_monitor_background_size===")
  }

  const monitor_dragdrop = () => {
    console.log("===monitor_dragdrop===")
  }

  return (
    <div className='flex flex-col'>
      <div className="wrapper flex flex-row min-h-[200px]">
        {/* <div>
        <p>{`x: ${posInfo.x}, y: ${posInfo.y}`}</p>
        <p>{`width: ${sizeInfo.width}, height: ${sizeInfo.height}`}</p>
      <button onClick={() => {
        // console.log({ width: curProject.p_image_w, height: curProject.p_image_h })
        // console.log({ left: curProject.p_image_l, top: curProject.p_image_t })
        console.log(sizeInfo)
        console.log(posInfo)
      }}>TEST</button>
      </div> */}
        <div className="item-side mr-1 z-10">
          {
            list.map(({ id, title, overload, value = t("Common.TrErr"), battery = 0, view_x, view_y }, index) => (
              <Draggable
                key={index}
                handle=".handle"
                position={{ x: parseInt(view_x ?? '0'), y: parseInt(view_y ?? '0') }}
                grid={[25, 25]}
                scale={1}
                disabled={locked}
                onStop={(e, data) => reposition_lc(e, data, index)}>
                <div className="handle border w-20 flex flex-col text-xs rounded cursor-pointer">
                  <div className="top-side bg-black text-white text-center rounded-t-sm py-0.5">{title}</div>
                  <div
                    id={`monitor${id}`}
                    className={`
                    bottom-side text-center py-0.5 rounded-b-sm
                    ${max ? 'bg-cyan2 text-white' : (!bleConnected ? 'bg-medium text-danger' : (`${value === t("Common.TrErr") ? 'bg-medium text-danger' : 'bg-green-600 text-white'}`))}
                  `}
                  >
                    {!load ? (bleConnected ? battery : t("Common.TrErr"))
                      : (max ? overload
                        : (bleConnected ? value : t("Common.TrErr")))}
                  </div>
                </div>
              </Draggable>
            ))
          }
        </div>
        <ResizableBox
          className={`border-black border rnd-container`}
          width={10000}
          height={parseInt(curProject.stage_y ?? '200')}
          axis='y'
          onResizeStop={handleFrameResize}
        >
          <div className="content-side flex-1 border relative z-0 dark:border-inherit w-full h-full ">
            {curProject.p_image &&
              <Rnd
                ref={rndRef}
                default={{
                  x: 0,
                  y: 0,
                  width: 100,
                  height: 100,
                }}
                onResizeStop={handleImageResize}
                onDragStop={handleImagePosition}
                minWidth={100}
                minHeight={100}
                bounds="parent"
                className='rnd-child'
              >
                <div
                  className="handle box border border-black dark:border-inherit dark:text-white"
                  style={{
                    minWidth: '100px',
                    minHeight: '100px',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${curProject.p_image})`,
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                </div>
              </Rnd>
            }

            <div className="control-btns flex flex-col absolute right-0 top-0">
              <IonButton color="medium" className="m-0" onClick={() => handlePos()}>
                <IonIcon slot="icon-only" icon={locateOutline} size="small"></IonIcon>
              </IonButton>
              <IonButton color="medium" className="m-0" onClick={() => setLocked(v => !v)}>
                <IonIcon slot="icon-only" icon={locked ? lockClosedOutline : lockOpenOutline} size="small"></IonIcon>
              </IonButton>
              <IonButton color="medium" className="m-0" onClick={() => setReset(v => !v)}>
                <IonIcon slot="icon-only" icon={logOutOutline} size="small"></IonIcon>
              </IonButton>
            </div>
          </div>
        </ResizableBox>
      </div>
      <div className='flex flex-row px-8 pt-5'>
        {curProject.show_graphs && graphData.length > 0 &&
          <Line
            datasetIdKey='id'
            data={{
              labels: labels,
              datasets: graphData
            }}
            options={{
              elements: {
                line: {
                  cubicInterpolationMode: 'monotone'
                }
              }
            }}
          />
        }
      </div>
    </div>
  )
}

export default MonitorView;
