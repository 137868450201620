import React, { FC, useEffect, useRef, useState } from "react";
import CommonLayout from "../../Layout/CommonLayout";
import './index.css';
import { IGroup, ILC } from "../../helper/types";

import useAppData from "../../hooks/useAppData";
import MonitorView from "../../components/Monitor/MonitorView";
import MonitorList from "../../components/Monitor/MonitorList";
import MonitorProg from "../../components/Monitor/MonitorProg";
import MonitorStop from "../../components/Monitor/MonitorStop";
import Text from "../../components/Text";
import { useTranslation } from "react-i18next";
import GroupActionModal from "../../components/Modals/GroupActionModal";
import SuccessModal from "../../components/Modals/SuccessModal";
// import * as database from '../../database'
import { db } from "../../db";
import Swal from "sweetalert2";
import { format, getTime } from "date-fns";
import { setGroupTare, setGroupZero } from "../../helper/db/groups";
import useFunctions from "../../hooks/useFunctions";
import { fire_error, getLCsByGroup, strToFloat } from "../../helper/functions";

const MonitorModals = {
  GroupAction: 'action',
  Zero: 'zero',
}
const Monitor: FC = () => {
  const {
    curProject,
    lcs,
    monitorStatus,
    bleConnected,
    logs,
    LCMax: lc_max,
    groups,
    lc_wind_alerts,
    liveLC,
    updateErrStr,
    updateLCs,
    updateGroups,
    updateTotalWeightHtml,
    updateWarnList,
    updateLCWindAlerts,
    updateLiveLC,
  } = useAppData()
  const { t } = useTranslation()

  const {
    f_load_groups,
    f_load_lcs,
    f_load_projects,
    f_update_project_last_change,
    f_update_project_image_size,
    f_update_project_image_position,
    f_load_cells,
  } = useFunctions()

  const weighing = {
    status: 0,
    net: 0,
    gross: 0,
    tare: 0,
  }

  const [groupList, setGroupList] = useState<IGroup[]>([])

  const [dbHanlder, setDBHandler] = useState(null)

  const [list, setListData] = useState<ILC[]>([]);
  const [prevState, setPrevState] = useState<Partial<ILC>[]>([]);

  const [maxStatus, setMaxStatus] = useState<boolean>(false)
  const [loadStatus, setLoadStatus] = useState<boolean>(true)
  const [tareStatus, setTareStatus] = useState<boolean>(false)
  const [warning, setWarning] = useState<boolean>(false)

  // const [selected, setSelected] = useState<IGroup | null>(null)
  const selectedRef = useRef<IGroup | null>()
  const [visibleModal, setVisibleModal] = useState<string>('');
  const [success, setSuccess] = useState<{ title: string; subtitle: string; } | null>(null)
  const [count, setCount] = useState(0);
  const [lcMonitorErrors, setLCMonitorErrors] = useState([]);
  const [lastAlert, setLastAlert] = useState<number>(getTime(new Date()));

  useEffect(() => {
    // Impementing the setInterval method
    const interval = setInterval(() => {
      setCount(count + 1)
    }, 1000)
    
    // Clearing the interval
    return () => clearInterval(interval)
  }, [])
  
  useEffect(() => {
    if (count === 3) {
      setCount(0)
      f_monitor_total_sum(lcs);
      groups_total_sum(lcs);
    }
  }, [count, lcs])

  useEffect(() => {
    console.log('lcs data: ', lcs)
  }, [lcs])

  useEffect(() => {
    if (curProject) {
      f_load_lcs()
    }
  }, [curProject])

  const f_monitor_total_sum = (lcList: ILC[]) => {
    if (!curProject.id || lcMonitorErrors.length > 0) {
      updateTotalWeightHtml('<span class="text-xs font-bold bg-danger text-white px-1.5 py-0.5 rounded">Tr. Err</span>');
      return
    }
    let t = 0;
    lcList.forEach((lc, index) => {
      let v = parseFloat(lc.value ?? '0')
      const p = parseFloat(lc.psw ?? '0')
      const z = lc.zero ?? 0
      v = v + z;
      if (v < 0) {
        v = 0;
      }
      v = v + p;
      t += (v <= 0) ? 0 : v;
    })
    if (isNaN(t)) {
      updateTotalWeightHtml('<span class="text-xs font-bold bg-danger text-white px-1.5 py-0.5 rounded">Tr. Err</span>');
    } else {
      if (t < 0) {
        t = 0;
      }
      updateTotalWeightHtml(curProject.units?.toLowerCase() !== "m.ton" ? t.toFixed(0) : t.toFixed(3));
      if (parseFloat(curProject.total_overload ?? '0') < parseFloat(t + '')) {
        const c = getTime(new Date());
        const d = getTime(lastAlert);
        const u = curProject.units?.toLowerCase().replace('.', '');
        if ((c - d) > 40000) {
          const fx = (u == "mton") ? 3 : 0;
          Swal.fire({
            title: '',
            html: "<h2 class='bg-danger text-white'>Total Overload!</h2><h2 class='bg-danger text-white'>Weight: " + parseFloat(t + '').toFixed(fx) + " | Overload: " + parseFloat(curProject.total_overload ?? '0').toFixed(fx) + "</h2>",
            heightAuto: false
          });
          setLastAlert(c);
        }
      }
    }
  }

  const groups_total_sum = (lcList: ILC[]) => {
    console.log('groups_total_sum: ', lc_max, lcs.length)
    if (curProject === null) {
      return;
    }
    if (lc_max) {
      return;
    }

    const g_totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const g_trerror: any[] = [];
    const u = curProject?.units?.toLowerCase().replace('.', '');
    const fx = (u == "mton") ? 3 : 0;
    if (lcList && lcList.length > 0) {
      lcList.forEach(function (lc) {
        if (lc.groups) {
          let v: any = lc.value || 'err';
          if (!isNaN(v)) {
            const p = parseFloat(lc.psw || '0');
            const z = lc.zero || 0;
            const t = lc.tare || 0;
            v = v + z - t;
            if (v < 0) {
              v = 0;
            }
            v = v + p;
            const g = lc.groups.split(',');
            g.forEach(function (a) {
              g_totals[parseInt(a) - 1] += v;
            });
          } else {
            const g = lc.groups.split(',');
            g.forEach(function (a) {
              g_trerror.push(parseInt(a) - 1);
            });
          }
        }
      });
      console.log('g_total : ', g_totals)
      console.log('')
      updateGroups(groups.map((item) => {
        const total = g_totals[parseInt(item.id) - 1]
        return ({
          ...item,
          sum: isNaN(total) || g_trerror.includes(parseInt(item.id)) ? t('Common.TrErr') : (item.overload ? total.toString() : ''),
        })
      }))
    }
    // $.each(g_totals, function (b, c) {
    //   if (g_trerror.includes(b)) {
    //     $(".grp[data-id='" + b + "'] .group_monitor,.totalizer_group[data-id='" + b + "'] .totalier_live,.weight_g_group[data-id='" + b + "'] .weighing_g_live").html('<span class="badge badge-danger">Tr. Err</span>');
    //   } else {
    //     if (!$(".grp[data-id='" + b + "'] .bg-primary span").hasClass('d-none')) {
    //       $(".grp[data-id='" + b + "'] .group_monitor,.totalizer_group[data-id='" + b + "'] .totalier_live,.weight_g_group[data-id='" + b + "'] .weighing_g_live").text(c < 0 ? 0 : parseFloat(c + '').toFixed(fx));
    //       if (weighing.status == 1) {
    //         const g = c < 0 ? 0 : parseFloat(c + '').toFixed(fx);
    //         weighing.net = parseFloat(g + '') - parseFloat(weighing.tare + '');
    //         weighing.gross = parseFloat(weighing.net + '') + parseFloat(weighing.tare + '');
    //         $(".weighing_g_net").text(weighing.net)
    //         $(".weighing_g_sum").text(weighing.gross)
    //       }
    //     }
    //   }
    // });

    // group overload 130% - alert - todo
    if (!bleConnected) return;

    groups.forEach(function (g: any) {
      if (g.id == 0) {
        return
      }
      // let v = parseFloat($("#monitor .grp[data-id='" + g.id + "'] .group_monitor").text());
      let v = g.value
      let o = parseFloat(g.overload);
      const p = v / o * 100;

      const u = curProject?.units?.toLowerCase().replace('.', '') //for m.ton;
      const fx = (u == "mton") ? 3 : 0;

      if (isNaN(v)) {
        v = 0;
      }
      if (isNaN(o)) {
        o = 0;
      }

      if (v > o) {
        // $("#monitor .grp[data-id='" + g.id + "'] .group_monitor").removeClass('bg-success bg-danger').addClass('bg-warning');
        fire_group_overload(g.id, v, o, false);
        updateWarnList({
          log_date: format(new Date(), "HH:mm"),
          title: (g.title) ? g.title : g.id,
          value: v.toFixed(fx),
          underload: g.underload,
          overload: g.overload,
        })
        setWarning(true)
      } else if (p > 130) {
        fire_group_overload(g.id, v, o, true);
        updateWarnList({
          log_date: format(new Date(), "HH:mm"),
          title: (g.title) ? g.title : g.id,
          value: v.toFixed(fx),
          underload: g.underload,
          overload: g.overload,
        })
        setWarning(true)
      }
    });
  }

  function fire_group_overload(group: any, weight: any, overload: any, danger: any) {
    const u = curProject?.units?.toLowerCase().replace('.', '') //for m.ton;
    const fx = (u == "mton") ? 3 : 0;
    groups.forEach((b) => {
      if (b.id == group) {
        const o = (danger) ? "DANGER" : "OVERLOAD";
        // $(".warning").addClass('active');
        Swal.fire({
          title: '',
          html: "<h2 class='bg-danger text-white'>" + o + "!</h2><h2 class='bg-danger text-white'>Group: " + group + "</h2><h2 class='bg-danger text-white'>Weight: " + parseFloat(weight).toFixed(fx) + " | Overload: " + parseFloat(overload).toFixed(fx) + "</h2>",
          heightAuto: false
        });
        // navigator.notification.beep(4);
        // groups[a].last_alert = moment().valueOf();
      }
    });
  }

  const handleMoveLC = async (lcItem: ILC) => {
    updateLCs(lcItem);
    await db.lcs.put(lcItem);
  }

  const handleReset = async (reset: boolean) => {
    if (lcs.length > 0) {
      const lcIds = lcs.map(item => item.lc_id)
      if (reset) {
        setPrevState(lcs)
        const updated = lcs.map(item => {
          if (lcIds.includes(item.lc_id)) {
            return { ...item, view_x: '0', view_y: '0' };
          } else
            return item
        })
        updateLCs(updated)
        await db.lcs.bulkPut(updated)
      } else {
        const updated = lcs.map(item => {
          if (lcIds.includes(item.lc_id)) {
            const findItem = prevState.find(pItem => pItem.lc_id === item.lc_id)
            return { ...item, ...findItem };
          } else
            return item
        })
        updateLCs(updated)
        await db.lcs.bulkPut(updated)
      }
    }
  }

  const contentView = () => {
    switch (monitorStatus) {
      case 'view':
        return (
          <MonitorView
            data={lcs}
            max={maxStatus}
            load={loadStatus}
            tare={tareStatus}
            onMoveLC={handleMoveLC}
            onReset={handleReset}
          />
        )
      case 'list':
        return (<>
          <MonitorList data={lcs} />
        </>)
      case 'prog':
        return (<>
          <MonitorProg
            data={lcs}
            unit={curProject.units || ''}
          />
        </>)
      case 'stop':
        return (<>
          <MonitorStop
            data={lcs}
            unit={curProject.units || ''}
            max={maxStatus}
          />
        </>)
      default:
        break;
    }
  }

  const handleGroup = (group: IGroup) => {
    if (!unique_group_lcs(group.id)) {
      fire_error('This group contain non unique load cells');
      return false;
    }

    if (bleConnected) {
      selectedRef.current = group
      console.log('@@@ handleGroup ', { group, bleConnected, MonitorModals, selectedRef: selectedRef.current })
      setVisibleModal(MonitorModals.GroupAction)
    } else {
      updateErrStr(t('Msg.ErrConnectPRR'))
    }
  }

  const handleGroupAction = async (type: string) => {
    console.log({ type })
    if (!selectedRef.current) return
    const groupShownId = selectedRef.current.id
    console.log('~~~~~~~~~~~handleGroupAction ', { selectedRef: selectedRef.current })
    switch (type) {
      case 'tare':
        if (!groupShownId) return
        console.log('Tare mode : ', { tare: selectedRef.current?.tare })
        if (selectedRef.current?.tare !== 'true') {
          // when clicked tare
          let tare_ok = true
          lcs.forEach(lc => {
            const g = lc.groups?.split(',')
            if (g?.includes(groupShownId)) {
              const v = strToFloat(liveLC?.value) + strToFloat(liveLC?.zero)
              if (v <= 0) {
                tare_ok = false
              }
            }
          })
          if (tare_ok) {
            await tare_group(groupShownId)
            if (tare_on(true)) {
              setSuccess({
                title: t('Monitor.Modal.TareMode'),
                subtitle: t('Monitor.Modal.TareSubtitle')
              })
            } else {
              return
            }
          } else {
            updateErrStr('You must select at least one group in tare mode. doubletap the group name and then click "tare" button')
          }
        } else {
          console.log('Untare mode : ', { tare: selectedRef.current?.tare })
          untare_group(groupShownId)
        }

        setVisibleModal('')

        // if (selectedRef.current && selectedRef.current.group_id) {
        //   if (selectedRef.current.tare === 'true') {
        //     // when clicked untare
        //     console.log('@@@ untare', { selected: selectedRef.current })
        //     const updatedGroup: IGroup = {
        //       ...selectedRef.current,
        //       tare: '',
        //     }
        //     updateGroups(updatedGroup)
        //     await setGroupTare(selectedRef.current.group_id, '')

        //     // untare_group(selectedRef.current.group_id)
        //   } else {
        //     // when clicked tare
        //     console.log('@@@ tare', { selected: selectedRef.current })
        //     const updatedGroup: IGroup = {
        //       ...selectedRef.current,
        //       tare: 'true',
        //     }
        //     updateGroups(updatedGroup)
        //     await setGroupTare(selectedRef.current.group_id, 'true')

        //     // tare_group(selectedRef.current.group_id)
        //   }
        // }
        // console.log('-----------  handleGrouAction  ', { selected: selectedRef.current })
        // setSuccess({
        //   title: t('Monitor.Modal.TareMode'),
        //   subtitle: t('Monitor.Modal.TareSubtitle')
        // })
        // setVisibleModal('')
        break;
      case 'zero':
        console.log('before zero')
        if (selectedRef.current && selectedRef.current.id) {
          console.log({ curProject })
          lcs.filter(lc => lc.project_id === curProject.id)
            .filter(lc => lc.groups?.split(',').includes(selectedRef.current?.id ?? ''))
            .map(item => {
                  if(!item.value)
                  {
                    updateErrStr('at least one of the lcs in the selected groups have a tr.err')                  
                    return;
                  }            
              })
          await setGroupZero(curProject.id, selectedRef.current.id)

          const updatedLcs: ILC[] = lcs.filter(lc => lc.project_id === curProject.id)
            .filter(lc => lc.groups?.split(',').includes(selectedRef.current?.id ?? ''))
            .map(item => ({
              ...item,
              psw: '0',
              zero: parseInt(item.realval || '0') * -1,
            }))
          updateLCs(updatedLcs)
        }
        setSuccess({
          title: `${t('Monitor.Modal.Zero')} ${selectedRef.current?.title}`,
          subtitle: t('Monitor.Modal.ZeroSuccessSubtitle', { group: selectedRef.current?.title })
        })
        setVisibleModal('')
        break;
      default:
        break;
    }
  }

  const handleCloseModal = () => {
    setVisibleModal('')
  }

  const update_project_image = async (project_id: any, image: any) => {
    try {
      await db.projects.update(project_id, { p_image: image });
      f_update_project_last_change()
    } catch (err) {
      console.error('Error updating row: ', err);
    }
  }

  const lc_show_max = () => {
    console.log("===lc_show_max===")
  }

  const handleTareAction = (type: string, groupShownId: string) => {
    if (type === 'tare')
      tare_group(groupShownId)
    else
      untare_group(groupShownId)
  }

  const tare_group = async (groupShownId: string) => {
    console.log('~~~~~~~~~~~tare_group')
    const lc_m = lcs.find(x => x.id === liveLC?.id);
    console.log({ lcs, lc_m, liveLC });
    try {
      if (!lc_m) {
        return
      }
      const g = lc_m?.groups?.split(',') ?? []

      if (g.includes(groupShownId)) {
        const v = liveLC.value
        await db.lcs.where({ id: lc_m.id, project_id: curProject.id })
          .modify({ tare: v })
        console.log('Tare value updated for lc', lc_m.id, 'to', v);
        const index = lcs.findIndex(x => x.id === lc_m.id);
        // lcs[index].tare = v;
        updateLCs({ ...lcs[index], tare: v })

        if (!selectedRef.current) return
        
        const updatedGroup: IGroup = {
          ...selectedRef.current,
          tare: 'true',
        }
        selectedRef.current = updatedGroup

        // Update Group - db/context
        updateGroups(updatedGroup)
        await setGroupTare(groupShownId, 'true')
        console.log({sele: selectedRef.current, updatedGroup})
        f_load_cells()
      }
      console.log('Tare values updated successfully');
    } catch (error) {
      console.error('Error updating tare value: ' + error)
    }
  }

  const untare_group = async (groupShownId: string) => {
    console.log('Untare group ' + groupShownId);
    try {
        const updates: { key: any; changes: any }[] = []
        const ids: number[] = []
        lcs.forEach((lc, index) => {
          const g = lc.groups?.split(',').map(item => parseInt(item, 10))
          if (g?.includes(parseInt(groupShownId))) {
            console.log('Set tare to 0 - lc #' + lc.id);
            updates.push({
              key: { id: lc.id, project_id: curProject.id },
              changes: { tare: '0' }
            })
            ids.push(index)
          }
        })

        // Update lcs in DB/Context
        db.lcs.bulkUpdate(updates)
        ids.forEach(item => {
          updateLCs({...lcs[item], tare: 0})
        })
        // Update groups in DB/Context
        if (!selectedRef.current) return
        const updatedGroup: IGroup = {
          ...selectedRef.current,
          tare: '',
        }
        selectedRef.current = updatedGroup
        updateGroups(updatedGroup)
        await setGroupTare(groupShownId, '')
        console.log({sele: selectedRef.current, updatedGroup})

      // })
      console.log('Untare OK');
    } catch (error) {
      console.log('Transaction ERROR: ' + error);
    }
    // const buf_groups = groups;
    // buf_groups[parseInt(group_id) - 1].tare = '';
    const updateGroup = groups.map(g => {
      if (g.id === groupShownId) {
        const { tare, ...rest } = g
        selectedRef.current = rest
        return rest
      }
      return g
    })

    console.log('untare_group', { updateGroup })
    updateGroups(updateGroup)
  }

  const unique_group_lcs = (group_id: string) => {
    // check if lcs in group are uinque for this group
    // that in order to prevent tare or zero groups
    // that the lc is showed in more than one group
    let unique = true;
    lcs.forEach(function (lc) {
      // let g = $.map(lc.groups.split(','), function (value:any) { return parseInt(value, 10); });
      const g = lc.groups?.split(',').map(item => parseInt(item, 10))
      if (g?.includes(parseInt(group_id))) {
        if (g.length > 1) {
          unique = false;
        }
      }
    });
    return unique;
  }

  const handleMaxStatus = (val: boolean) => {
    if (val)
      setLoadStatus(true)
    setMaxStatus(val)
  }

  const handleLoadStatus = (val: boolean) => {
    if (!val)
      setMaxStatus(false)
    setLoadStatus(val)
  }

  const tareStatusToggle = () => {
    if (tareStatus)
      tare_off()
    else
      tare_on();
  }

  const tare_on = (isTared = false) => {
    let go_tare = 0
    groups.forEach((group) => {
      if (group.tare === 'true') {
        go_tare = 1
        // $(".grp[data-id='" + group.id + "'] .group_header span").text('NET');
      }
    })
    if (go_tare == 1 || isTared) {
      setTareStatus(true)
      return true
    } else {
      setTareStatus(false)
      fire_error('You must select at least one group in tare mode. doubletap the group name and then click "tare" button');
      return false
    }
  }

  const tare_off = async () => {
    console.log("===tare_off===")
    setTareStatus(false)
    const newGroups = groups.map((group, index) => ({
      ...group,
      tare: '',
    }))
    updateGroups(newGroups)
    try {
      await db.lcs.toCollection().modify({ tare: 0 })
      console.log('Tare values updated successfully');
      f_load_cells()
      return true
    } catch (error) {
      console.error('Error updating tare values: ' + error);
      return false
    }
  }

  return (
    <CommonLayout
      classes='p-2 gap-1'
      maxStatus={maxStatus}
      loadStatus={loadStatus}
      tareStatus={tareStatus}
      warning={warning}
      maxStatusToggle={handleMaxStatus}
      loadStatusToggle={handleLoadStatus}
      tareStatusToggle={tareStatusToggle}
      onWarning={setWarning}
      onDBHandler={setDBHandler}
      onTareAction={handleTareAction}
    >
      <div className='grid grid-cols-16 h-10 w-full'>
        {groups.map((item: IGroup, index: number) => {
          let v = parseFloat(item.sum ?? '')
          let o = parseFloat(item.overload);
          const p = v / o * 100;

          const u = curProject?.units?.toLowerCase().replace('.', '') //for m.ton;
          const fx = (u == "mton") ? 3 : 0;

          if (isNaN(v)) {
            v = 0;
          }
          if (isNaN(o)) {
            o = 0;
          }

          return (
            <div
              key={index}
              className={`flex flex-col border-l border-dark cursor-pointer ${index === groups.length - 1 && 'border-r'}`}
              onDoubleClick={() => handleGroup(item)}
            >
              <Text
                classes={`
                  h-1/2 bg-primary text-dark !text-xs flex items-center justify-center
                  ${v > o ?
                    'bg-warning'
                    :
                    `${p > 130 ?
                      'bg-danger'
                      :
                      `${item.sum ? '' : ''}`
                    }`
                  }
                  ${p > 1}
                `}
                label={item.overload ? (tareStatus && item.tare === 'true' ? 'NET' : item.title) : ''}
              />
              {bleConnected ?
                <Text
                  classes='h-1/2 bg-medium text-dark !text-xs flex items-center justify-center'
                  label={(item.sum) ? parseFloat(item.sum).toFixed(fx) : ''}
                />
                :
                <div className="h-1/2 w-full bg-medium text-center flex justify-center items-center">
                  <span className={`text-xs font-bold ${item.overload && 'bg-danger'} text-white px-1.5 rounded w-max`}>{item.overload ? t("Common.TrErr") : ''}</span>
                </div>
              }
            </div>
          )
        })}
      </div>
      {contentView()}
      <GroupActionModal
        visible={visibleModal === MonitorModals.GroupAction}
        tare={selectedRef.current && selectedRef.current?.tare === 'true' ? true : false}
        onAction={handleGroupAction}
        onClose={() => handleCloseModal()}
      />
      <SuccessModal
        visible={success ? true : false}
        title={success?.title || ''}
        message={success?.subtitle || ''}
        classes="gap-4"
        titleClasses='!text-3xl'
        onClose={() => setSuccess(null)}
      />
    </CommonLayout>
  )
}

export default Monitor;