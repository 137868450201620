import React, { FC, useEffect, useState } from 'react';
import { IonIcon, IonImg } from '@ionic/react';
import { cameraSharp, imageSharp } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';
import Text from '../Text';
import { IProject } from '../../helper/types';
import { Capacitor } from '@capacitor/core';
import useAppData from '../../hooks/useAppData';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);
interface ProjectInformationModalProps {
  visible: boolean;
  onAction: (url: string) => void;
  onClose: () => void;
}

const ProjectInformationModal: FC<ProjectInformationModalProps> = props => {
  const {
    visible,
    onAction,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { isMobile } = useAppData();

  const [img, setImg] = useState<string>('');

  useEffect(() => {
    return () => setImg('')
  }, [])

  useEffect(() => {
    if (img) {
      onAction(img)
    }
  }, [img])

  const hanldeImgLoad = async (type: string) => {
    try {
      if (type === 'camera') {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          saveToGallery: true
        })

        setImg((isMobile ? image.path : image.webPath) || '')
        console.log('image result: ', image)
      } else {
        const image = await Camera.getPhoto({
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: CameraSource.Photos,
          saveToGallery: true
        })

        setImg((isMobile ? image.path : image.webPath) || '')
        console.log('image reuslt: ', image)
      }
    } catch (error) {
      console.log('image result error: ', error)
    }
  }

  return (
    <Modal
      visible={visible}
      header={t("Project.ProjectInformation")}
      classes={` ${isMobile ? 'w-[600px] max-h-[340px] border border-secondary rounded-md overflow-auto' : 'w-[600px] border border-secondary rounded-md overflow-auto'}`}
      contentClasses='py-2 px-6 gap-2'
      footerClasses='!justify-center'
      onClose={() => onClose()}
    >
      <Text label={t("Project.ProjectInformationSubtitle")} />
      <div className='flex flex-row items-center gap-2 w-full'>
        <IonIcon
          src={imageSharp}
          color='primary'
          className='text-3xl rounded'
          onClick={() => hanldeImgLoad('gallery')}
        />
        <IonIcon
          src={cameraSharp}
          color='primary'
          className='text-4xl rounded'
          onClick={() => hanldeImgLoad('camera')}
        />
      </div>
      {img &&
        <IonImg src={Capacitor.convertFileSrc(img)} />
      }
    </Modal>
  )
}

export default ProjectInformationModal;
