
import { useTranslation } from "react-i18next";
import useAppData from "./useAppData";
import { db } from "../db";
import { IGroup, ILC, IProject, IProjectDetail } from "../helper/types";
import { LC_Serials, LC_SerialsType } from "../helper/constants";
import { getTime } from "date-fns";


export default function useFunctions() {
  const {
    curProject,
    lcs,
    monitorStatus,
    bleConnected,
    logs,
    LCMax: lc_max,
    groups,
    batteryStatus,
    updateMode,
    updateErrStr,
    updateSuccessStr,
    init,
    updateMonitorStatus,
    updateProjects,
    updateCurProject,
    updateLCs,
    updateGroups,
    updateVisibleModal,
    updateBleConnected,
    updateBatteryStatus,
    updateConfirmed,
    updateLogs,
    updateTotalWeightHtml,
    updateLCMax,
    updateWarnList,
    updateLiveLC,
    updateWeighData,
    updateLCWindAlerts,
    updateLCAlerts,
  } = useAppData()
  const { t } = useTranslation()


  const f_load_projects = async () => {
    const projectData = await db.projects.toArray();
    if (projectData.length > 0) {
      updateProjects(projectData)
      updateCurProject(projectData[0])
    }
  }

  const f_use_insert_project = async (newData: any) => {
    let newProjectId = '';
    try {
      const projectsStore = db.projects;
      const newid = await projectsStore.add(newData);
      // projectsStore.add(newData).then(async function (newid) {
      newProjectId = newid;
      console.log('Data added successfully', newid);
      // load_projects();


      const newGroupList: Partial<IGroup>[] = Array(16).fill(0).map((item, index) => ({
        project_id: newProjectId,
        id: (index + 1).toString(),
        overload: '',
        title: `Grp ${index + 1}`
      }))
      const newList: any[] = [];
      await Promise.all(newGroupList.map(async (item) => {
        const newItem = await db.groups.add(item)
        newList.push({ ...item, group_id: newItem })
      }))
      console.log('newList: ', newList)

      newData = { ...newData, id: newid }
      updateProjects(newData);
      updateCurProject(newData);
      updateLCs([]);
      updateGroups(newList)

      return newProjectId;

    } catch (error) {
      console.error('Error adding data to the table: ' + error);
      return newProjectId;
    }
  }

  const f_use_update_project_setting = async (id: any, data: Partial<IProject>, multiply: number, weight: any) => {
    try {
      const projectsStore = db.projects;
      await projectsStore.update(id, data);
      console.log('project settings updated successfully');
      updateCurProject(data)
      updateProjects(data)
      f_update_units(multiply, weight)
      return true;
    } catch (error) {
      console.error('Error updating row: ' + error);
      return false
    }
  }

  const f_load_lcs = async () => {
    if (!curProject.id) return
    const myLcsStore = db.lcs;
    const key = 'project_id';
    const value = curProject.id;
    const _lcs: any = [];
    myLcsStore.where(key).equals(value).toArray().then(function (data) {
      // const logId
      data.forEach(function (item) {
        _lcs.push({
          ...item,
          view_x: item.view_x || '0',
          view_y: item.view_y || '0',
          value: '0',
          max: item.max ?? '0',
        })
      });
      console.log('***** ', { _lcs })
      updateLCs(_lcs);
    }).catch(function (error) {
      console.error('Error querying data from the table: ' + error);
    });
  }

  const f_load_groups = async () => {
    if (!curProject.id) return
    const myGroups = db.groups;
    const key = 'project_id';
    const value = parseInt(curProject.id);
    myGroups.where(key).equals(value).toArray().then(function (data) {
      console.log('load_groups', { data })
      updateGroups(data)
    }).catch(function (error) {
      console.error('Error querying data from the table: ' + error);
    });
  }
  const init_groups = () => {
    console.log('init_groups')
    updateGroups([])
  }

  const f_update_project_last_change = () => {
    const time = new Date().getTime();
    const updatedProject = { ...curProject, last_settings_change: time.toString() }
    updateCurProject(updatedProject)
    updateProjects(updatedProject);
  }

  const f_insert_lc = async (lc: Partial<ILC>) => {
    const { id: lcTableId = '', title = '', psw = '', underload = '', overload = '', total_sum = '', groups = '', project_id = '' } = lc

    console.log('===insert_lc===')
    const newLC = {
      id: lcTableId,
      title,
      psw,
      underload,
      overload,
      total_sum,
      groups,
      project_id,
      tare: '',
      zero: '',
    }
    try {
      await db.lcs.add(newLC)
      console.log('lc added successfully')
      f_load_cells();
      f_update_project_last_change();
      // $("#newproject").modal('hide');  // create new project tab
      // $("#navbarDropdown").click(); // goto project tab
    } catch (error) {
      console.error('Error adding data to the lc table: ' + error)
    }
  }

  const f_load_cells = async () => {
    if (!curProject.id) return

    try {
      const data: ILC[] = await db.lcs.filter(lc => lc.project_id === curProject.id).toArray()
      console.log("I'm data", data)
      data.forEach(item => {
        // draw_lc_row(item, data.length);
        // draw_lc_monitor(item);
        // draw_lc_group(item);
        if (parseInt(item.id) <= 10) {
          updateLCWindAlerts({ id: item.id, last_alert: 0 })
        } else {
          updateLCAlerts({ id: item.id, last_alert: 0 })
        }
      })
      updateLCs(data)
      // monitor_dragdrop() // in Monitor Page - LC Drag & Resize
      if (curProject.show_graphs) {
        // draw_chart('lightChart', curProject.lcs)
      }
    } catch (error: any) {
      console.error('Error querying data from the table: ' + error)
    }
  }

  const f_edit_lc = async (lc: Partial<ILC>) => {
    const { id: lcTableId, title = '', psw = '', underload = '', overload = '', total_sum = '', groups = '', project_id = '' } = lc
    const selectedLc = lcs.find(x => x.id === lcTableId)

    if (!selectedLc) return

    const updatedData = {
      title,
      psw,
      underload,
      overload,
      total_sum,
      groups,
      project_id,
    }
    try {
      await db.lcs.update(selectedLc.lc_id, updatedData)
      console.log('Row updated successfully')
      f_load_cells()
      // $("#newproject").modal('hide');
      // $("#navbarDropdown").click();
    } catch (error) {
      console.error('Error updating row: ' + error)
    }
    f_update_project_last_change()
  }

  const f_update_lcs = async (lcList: ILC[]) => {
    try {
      await db.lcs.bulkPut(lcList)
      updateLCs(lcList)
    } catch (error) {
      console.error('Error updating row: ' + error)
    }
    f_update_project_last_change()
  }

  const f_delete_lc = async (id: string) => {
    const idToDelete = id

    try {
      const primaryKeys = await db.lcs.where('id').equals(idToDelete)
        .filter(lc => lc.project_id === curProject.id)
        .primaryKeys() // Retrieves the primary keys of the records

      await db.lcs.bulkDelete(primaryKeys)
      console.log('Records successfully deleted')
      f_load_cells()
    } catch (error) {
      console.error('Failed to delete records:', error)
    }
  }

  const f_random_rgba = () => {
    const o = Math.round, r = Math.random, s = 255
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 1 + ')'
  }

  const f_update_units = async (multiply: number, weight: any) => {
    console.log("I'm curProject", curProject)
    try {

      await Promise.all([
        db.groups.where('project_id').equals(curProject.id)
          .modify(group => {
            if (!group.overload) return
            group.overload = (group.overload * multiply).toFixed(3)
            // group.tare = (group.tare * multiply).toFixed(3)
          }),
        db.lcs.where('project_id').equals(curProject.id).modify(lc => {
          if (lc.id > 10) {
            lc.overload = (lc.overload * multiply).toFixed(3)
            lc.underload = (lc.underload * multiply).toFixed(3)
            lc.psw = (lc.psw * multiply).toFixed(3)

          } else {
            lc.overload = (lc.overload * weight)
            lc.underload = (lc.underload * weight)
            lc.psw = (lc.psw * weight)

          }
        })
      ])
      console.log('Values updated successfully')
    } catch (error) {
      console.error('Error updating values: ' + error)
    }
  }
  const f_get_units_multiply = (units: string) => {
    const originUnits = curProject.units
    const updatedUnits = units
    let multiply = 0;
    switch (originUnits) {
      case "KG":
        // fixed = 0;
        multiply = (updatedUnits == "LBS") ? 2.20462 /*lbs*/ : 0.001 /*mton*/;
        break;
      case "LBS":
        // fixed = 0;
        multiply = (updatedUnits == "KG") ? 0.453592 /*kg*/ : 0.000453592 /*mton*/;
        break;
      case "M.TON":
        // fixed = 3;
        multiply = (updatedUnits == "KG") ? 1000 /*kg*/ : 2204.62 /*lbs*/;
        break;
    }
    return multiply;
  }

  const f_project_groups = async () => {
    try {
      // Use where to create an index lookup
      const data: IGroup[] = await db.groups.where('project_id').equals(curProject.id).toArray()
      // 'data' is an array containing items that match the key-value pair

      console.log('f_project_groups', { data })

      updateGroups(data.map(g => ({ ...g, last_alert: 0 })))

      // clear_groups();  // UI: clear all groups in all page for refrech
      data.forEach((grp) => {
        console.log(grp)

        // UI: show groups to 'Select Group' options in 'Proof Test' page.
        // const proofGroups = document.getElementById("proof_groups");
        // proofGroups.insertAdjacentHTML('beforeend', `<option value="${grp.group_id}">${grp.title}</option>`);
      });

      // set_group_divs();
    } catch (error) {
      console.error('Error querying data from the table: ' + error)
    }
  }
  const f_save_group = async (project_id: string, groupTableId: string, title: string, overload: string) => {
    console.log('f_save_group', { project_id, group_id: groupTableId, title, overload })
    try {
      const selGrps = await db.groups.where('id').equals(groupTableId).toArray()
      const selGrp = selGrps[0]
      console.log({ groupTableId, selGrps, selGrp })
      const selGrpOverload = selGrp.overload
      const newGroup: Partial<IGroup> = {
        ...(title ? { title } : {}),
        ...(overload ? { overload } : {}),
        tare: '',
        sum: '',
      }
      if (Number.isFinite(parseFloat(selGrpOverload))) {
        delete newGroup.overload
      }

      console.log({ newGroup, overload, title })
      await db.groups.where('id').equals(groupTableId).modify({ ...newGroup })
      // Update app.active_project.groups array
      updateGroups({
        ...selGrp,
        ...newGroup,
      })
      console.log('Group updated successfully')
      f_update_project_last_change();
    } catch (error) {
      console.error('Error updating group: ' + error);
    }
  }

  const f_update_groups = async (groupList: IGroup[]) => {
    try {
      await db.groups.bulkPut(groupList);
      updateGroups(groupList)
    } catch (error) {
      console.error('Error updating row: ' + error)
    }
    f_update_project_last_change()
  }

  // const f_update_project_settings = async (project_id: any, updateData: Partial<IProject>) => {

  //   const res = await f_use_update_project_setting(project_id, updateData);
  //   if (res) {
  //     f_update_project_last_change();
  //     f_project_groups();
  //     f_load_cells();
  //   } else {
  //     console.error('Error updating row');
  //   }
  // }

  const f_update_project_details = async (data: IProjectDetail) => {
    try {
      const { id, project_id, ...rest } = data;
      await db.projects
        .where('project_id')
        .equals(project_id)
        .and(function (item) {
          return item.id === id;
        })
        .modify(rest)

      console.log('update sccuess');
      f_load_cells();
    } catch (error) {
      console.log(error);
    }
  }

  const f_update_project_details_image = async (project_id: any, image: any) => {
    console.log('===update_project_details_image===')
    try {
      await db.project_details
        .where('project_id')
        .equals(project_id)
        .modify({ logoheaderpdf: image })
      console.log('Successfully updated project details iamge');

    } catch (error) {
      console.log('update_project_details_image: ', error);
    }
  }

  const f_update_project_image_size = async (project_id: string, width: number, height: number) => {
    try {
      await db.projects.update(project_id, {
        p_image_w: width, p_image_h: height
      });
      f_update_project_last_change()
    } catch (error) {
      console.error('Error updating row: ' + error);
    }
  }

  const f_update_project_image_position = async (project_id: string, top: number, left: number) => {
    try {
      await db.projects
        .where('id')
        .equals(project_id)
        .modify({ p_image_t: top, p_image_l: left })
      // Successfully updated
      console.log('Project image position updated successfully');
    } catch (err) {
      console.error('Error updating project image position: ', err);
    }
  }

  const f_log_prooftest_value = async (lc: any, id: any, weight: any, realval: any, overload: any, underload: any, selected_group: any) => {
    console.log('log_prooftest_value ', lc, id, weight, realval, overload, underload, selected_group)
    try {
      await db.logs_proofftest
        .add({
          lc_id: parseInt(lc),
          project_id: id,
          log_date: new Date().getTime(),
          value: parseFloat(weight),
          realval: parseFloat(realval),
          overload: overload,
          underload: underload,
          unit: curProject.units,
          group_id: selected_group
        })
      console.log('Log inserted successfully');
    } catch (error) {
      console.error('Error inserting log: ' + error);
    }
  }

  const f_calibreate = async (lc: any, v: any) => {
    console.log('===calibreate===')
    try {
      await db.lcs
        .where('id')
        .equals(lc)
        .modify({ calibration_offset: v })
        .then(function () {
          console.log('Calibration offset updated successfully for lc', lc);
        })
    } catch (error) {
      console.error('Error updating calibration offset: ' + error);
    }
  }

  const f_convert_wind_speed = (a: any, b: any) => {
    return a * b
  }

  const f_verify_lc_id = (id: string) => {
    let lc: LC_SerialsType | undefined
    LC_Serials.forEach(function (a) {
      a.range.forEach(function (b: { from: number; to: number; }) {
        if (parseInt(id) >= b.from && parseInt(id) <= b.to) {
          lc = a;
        }
      })
    });
    console.log('===verify_lc_id===', { id, verifiedLCId: lc })
    return lc;
  }

  const f_check_lc_in_project = (lc: string[] | string) => {
    if (curProject === null) {
      return;
    }
    if (typeof lc == "string") {
      // lc is number - single one
      lc = [lc]
    }
    const in_project: string[] = [];
    if (lcs) {
      lcs.forEach(function (a) {
        if (lc.includes(a.id)) {
          in_project?.push(a.id);
        }
      });
    }
    return in_project;
  }

  const f_lc_capacity_id = (id: number) => {
    let c: any = false;
    const f = false;
    LC_Serials.forEach(function (lc) {
      lc.range.forEach(function (r) {
        if (id >= r.from && id <= r.to) {
          c = lc.capacity;
        }
      });
      if (f) {
        return;
      }
    });
    return c;
  }

  const f_lc_by_id = (lc_id: number) => {
    return lcs.find(({ id }) => id === lc_id.toString())
  }

  const f_log_lc_value = (lc_id: any, project_id: any, value: any, realval: any, overload: any, underload: any) => {
    console.log(lc_id, project_id, value, realval, overload, underload)
    const unit_value = (parseInt(lc_id) < 10) ? curProject.windmeter_units : curProject.units;

    const battery = batteryStatus; //parseInt(document.querySelector('#monitor_table tbody [data-id="' + lc_id + '"] td[data-battery]').innerHTML.replace('%', ''));

    db.logs
      .add({
        lc_id: parseInt(lc_id),
        project_id: project_id,
        log_date: getTime(new Date()),
        value: parseFloat(value),
        realval: parseFloat(realval),
        overload: overload,
        underload: underload,
        unit: unit_value,
        battery: battery
      })
      .then(function (id) {
        updateLogs(
          {
            id,
            lc_id: parseInt(lc_id) + '',
            project_id: project_id,
            log_date: getTime(new Date()) + '',
            value: parseFloat(value),
            realval: parseFloat(realval) + '',
            overload: overload,
            underload: underload,
            unit: unit_value,
            battery: battery.toString(),
          }
        )
        //  console.log('Log inserted successfully');
      })
      .catch(function (error: any) {
        console.error('Error inserting log: ' + error);
      });
  }

  const f_log_delete = async () => {
    const primaryKeys = await db.logs.where('project_id').equals(curProject.id)
      .primaryKeys() // Retrieves the primary keys of the records

    await db.logs.bulkDelete(primaryKeys)

    updateLogs([])
  }
  const f_reposition_stage = async (project_id: string, width: number, height: number) => {
    try {
      const updatedData = { stage_x: width, stage_y: height }
      await db.projects.update(project_id, updatedData)
      console.log('Row updated successfully')
    } catch (error) {
      console.error('Error updating row: ' + error)
    }
  }

  const toReturn = {
    f_load_lcs,
    f_load_groups,
    f_load_projects,
    f_use_insert_project,
    f_use_update_project_setting,
    f_update_project_last_change,
    f_load_cells,
    f_insert_lc,
    f_edit_lc,
    f_update_lcs,
    f_delete_lc,
    f_random_rgba,
    f_update_units,
    f_get_units_multiply,
    f_project_groups,
    f_save_group,
    f_update_groups,
    // f_update_project_settings,
    f_update_project_details,
    f_update_project_details_image,
    f_update_project_image_size,
    f_update_project_image_position,
    f_log_prooftest_value,
    f_convert_wind_speed,
    f_calibreate,
    f_verify_lc_id,
    f_check_lc_in_project,
    f_lc_capacity_id,
    f_lc_by_id,
    f_log_lc_value,
    f_log_delete,
    f_reposition_stage
  }
  return toReturn

}