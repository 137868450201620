import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonLayout from '../../Layout/CommonLayout';
import { IGroup, ILC, IProject } from '../../helper/types';
import Button from '../../components/Buttons/Button';
import CustomDataGrid from '../../components/CustomDataGrid';
import { addOutline, removeOutline } from 'ionicons/icons';
import useAppData from '../../hooks/useAppData';
import NewLCModal from '../../components/Modals/NewLCModal';
import { getNewLCIds, getValidLcIdList } from '../../helper/functions';
import { Unit_List, Windmeter_Unit_List } from '../../helper/constants';
import DeleteConfirmModal from '../../components/Modals/DeleteConfirmModal';
import SuccessModal from '../../components/Modals/SuccessModal';
import Text from '../../components/Text';
import GroupModal from '../../components/Modals/GroupModal';
import CalibrationModal from '../../components/Modals/CalibrationModal';
import { db } from '../../db'
import useFunctions from '../../hooks/useFunctions';
import './index.css';

enum CalibrationModalMode {
  'None' = 0,
  'Calibrate',
  'Confirm',
}

const Settings: FC = () => {
  const { t } = useTranslation();
  const { curProject, lcs, groups, updateErrStr, updateLCs, updateGroups } = useAppData();
  const { f_edit_lc, f_verify_lc_id, f_check_lc_in_project, f_insert_lc, f_save_group, f_project_groups, f_delete_lc } = useFunctions()

  const [dbHanlder, setDBHandler] = useState(null)

  const [loading, setLoading] = useState<boolean>(false);
  const [visibleAddModal, setVisibleAddModal] = useState<boolean>(false);
  const [calibrationModalMode, setVisibleCalibrationModal] = useState<CalibrationModalMode>(CalibrationModalMode.None);
  const [selectedProject, setSelectedProject] = useState<IProject>({} as IProject);
  const [LCList, setLCList] = useState<ILC[]>([])
  const [LCItem, setLCItem] = useState<ILC>({} as ILC)

  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState<boolean>(false);
  const [deleteList, setDeleteList] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [groupList, setGroupList] = useState<IGroup[]>([])
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null)
  const [errMsg, setErrMsg] = useState<string>('')

  useEffect(() => {
    if (lcs.length > 0)
      setLCList(lcs.filter(item => item.project_id === curProject.id))
    else
      setLCList([])
  }, [lcs])

  // useEffect(() => {
  //   // console.log('groups change: ', groups)
  //   setGroupList(groups)
  // }, [groups])

  const handleDeleteList = (id: string) => {
    if (deleteList.includes(id))
      setDeleteList(v => v.filter(item => item !== id))
    else
      setDeleteList(v => [...v, id])
  }
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
  
    if (isChecked) {
      const allIds = LCList.map(row => row.id); // Assuming `rows` is your data array
      setDeleteList(allIds);
    } else {
      setDeleteList([]);
    }
  };
  
  const handleDeleteCheck = (e: ChangeEvent<HTMLIonCheckboxElement>, id: string) => {
    e.preventDefault()
    handleDeleteList(id)
  }

  const handleDeleteAction = async () => {
    const filteredList: ILC[] = lcs.filter(item => !deleteList.includes(item.id))
    updateLCs(filteredList)
    const delLCList = lcs.filter(item => deleteList.includes(item.id)).map(item => item.lc_id)

    await db.lcs.bulkDelete(delLCList)

    setDeleteList([])
    setVisibleDeleteModal(false)
    setVisibleSuccessModal(true)
  }


  useEffect(() => {
    console.log("curproject", curProject)
  }, [curProject])

  const columns = [
    {
      flex: 0.079,
      minWidth: 60,
      field: 'id',
      headerName: t('Setting.Id'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{row.id}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'title',
      headerName: t('Setting.Name'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{row.title}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'Capacity',
      headerName: t('Setting.Capacity'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{parseFloat(row.overload).toFixed(2)}{row.id >= 10 ? curProject.units : curProject.windmeter_units}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'underload',
      headerName: t('Setting.Underload'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{parseFloat(row.underload).toFixed(2)}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'overload',
      headerName: t('Setting.Overload'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{parseFloat(row.overload).toFixed(2)}</span>
    },
    {
      flex: 0.105,
      minWidth: 80,
      field: 'psw',
      headerName: t('Setting.PSW'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{row.psw || 0}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'total_sum',
      headerName: t('Setting.Total'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{row.total_sum ? 'Yes ' : 'No'}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'groups',
      headerName: t('Setting.Groups'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className="text-dark dark:text-light col-item" onClick={() => handleEditLC(row)}>{row.groups || ''}</span>
    },
    {
      flex: 0.105,
      minWidth: 80,
      field: 'check',
      headerName: t('Setting.Check'),
      // eslint-disable-next-line
      // @ts-ignore
      renderHeader: () => (
        <input
          type='checkbox'
          checked={selectAll}
          onChange={handleSelectAll}
        />
      ),
      // eslint-disable-next-line
      //@ts-ignore
      renderCell: ({ row }) => <div onClick={(e) => handleDeleteCheck(e, row.id)}>
        <input type='checkbox' checked={deleteList.includes(row.id)} onChange={(e: any) => handleDeleteCheck(e, row.id)} />
      </div>
    },
  ];

  const handleNewLC = () => {
    setLCItem({} as ILC)
    setVisibleAddModal(true);
  }

  const handleLCAction = async (type: string, lc: Partial<ILC> = {}) => {
    switch (type) {
      case 'save': {
        const { unitList, overload, underload, psw, total_sum, title, groups: lcGroups = '' } = lc
        if (!overload || parseFloat(overload) <= 0) {
          updateErrStr(t('Msg.ErrOverload'))
          return
        }
        if (!underload || parseFloat(underload) > parseFloat(overload)) {
          updateErrStr(t('Msg.ErrUnderloadBigger'))
          return
        }

        if (psw && parseFloat(psw) < 0) {
          updateErrStr(t('Msg.ErrPSWNegative'))
          return
        }

        const res = getValidLcIdList(lc.id ? lc.id : unitList ?? '')
        if (!res.isValid) {
          updateErrStr(`Invalid LC unit's range ${res.startRange}-${res.endRange}. Left-hand operator must be smaller than the right-hand operator.`);
          return
        }
        const { ids: valid_ids } = res

        if (!curProject) {
          setVisibleAddModal(false);
          return
        }

        for (const lc of valid_ids) {
          const id_cap = f_verify_lc_id(lc);
          if (!id_cap) {
            updateErrStr('Invalid unit id ' + lc)
            return
          }

          if (!curProject.units) {
            setVisibleAddModal(false);
            return
          }

          const max_capacity = parseFloat(id_cap.capacity[curProject.units.replace('.', '').toLowerCase()])
          if (parseFloat(overload) > max_capacity) {
            updateErrStr(`Overload can't be higher than capacity, LC: ${lc}, Max Capacity: ${max_capacity}${curProject.units}, Requested Overload: ${overload}${curProject.units}`)
            return
          }

          if (parseFloat(psw ?? '') > max_capacity) {
            updateErrStr(`PSW can't be higher than capacity, LC: ${lc}, Max Capacity: ${max_capacity}${curProject.units}, Requested PSW: ${psw}${curProject.units}`)
            return
          }
        }

        if (!lc.id) {
          const lcs_in_project = f_check_lc_in_project(valid_ids);
          if (lcs_in_project && lcs_in_project.length > 0) {
            updateErrStr('Those units already added to the project: ' + lcs_in_project.join(","));
            return
          }
        }

        const newLCList: Partial<ILC>[] = [];
        valid_ids.forEach(id => {
          const newLC = {
            id,
            title,
            psw,
            underload,
            overload,
            total_sum,
            groups: lcGroups,
            project_id: curProject.id,
            lc_id: lc.lc_id,
          }
          newLCList.push(newLC);

          if (lc.id) {
            f_edit_lc(lc)
          } else {
            f_insert_lc(newLC)
          }
        })

        const groups_list = lcGroups.split(',');
        if (groups_list.length > 0) {
          groups_list.forEach(g => {
            if (!isNaN(parseInt(g)) && groups[parseInt(g)]?.id) {
              f_save_group(curProject.id, g, `Grp ${g}`, '0')
            }
          });
        }
        f_project_groups()

        setVisibleAddModal(false);
        break;
      }
      case 'duplicate':
        break;
      case 'delete': {
        f_delete_lc(LCItem.id)
        setVisibleAddModal(false)
        break;
      }
      case 'calibration':
        setVisibleCalibrationModal(CalibrationModalMode.Calibrate)
        break;
      case 'calibration-delete':
        setVisibleCalibrationModal(CalibrationModalMode.Confirm)
        updateLCs({ ...LCItem, calibration_offset: '1' })
        calibreate(LCItem.lc_id, '1')
        break;
      default:
        break;
    }
  }

  const clear_lc_table = () => {
    console.log('===clear_lc_table===')
  }

  const draw_lc_row = (row: any) => {
    console.log('===draw_lc_row===')
  }

  const calibreate = async (lc_id: string, v: string) => {
    console.log('===calibreate===')
    try {
      await db.lcs
        .where('lc_id')
        .equals(lc_id)
        .modify({ calibration_offset: v })
        .then(function () {
          console.log('Calibration offset updated successfully for lc', lc_id);
        })
    } catch (error) {
      console.error('Error updating calibration offset: ' + error);
    }
  }

  const handleCalibrationAction = async (diff: string) => {
    if (!Number.isFinite(diff)) diff = ''
    const updated = { ...LCItem, calibration_offset: diff }
    updateLCs(updated)
    calibreate(LCItem.lc_id, diff)
  }

  const handleChangeGroup = async (group: IGroup) => {
    if (!group.overload || parseFloat(group.overload) === 0) {
      setErrMsg(t('Msg.ErrOverload'))
    } else {
      setErrMsg('')
      updateGroups(groups.map(item => {
        if (item.id === group.id)
          return group
        else
          return item
      }))
      setSelectedGroup(null)
      await db.groups.put(group);
    }
  }

  const handleEditLC = (lc: ILC) => {
    setLCItem(lc)
    setVisibleAddModal(true)
  }
  const gOverload = (overload: string) => {
    const f = curProject.units === Unit_List[2].value ? 3 : 0
    const res = overload && parseFloat(overload).toFixed(f).toString()
    return res
  }
  return (
    <CommonLayout classes='p-2 gap-1' onDBHandler={setDBHandler}>
      <div className='grid grid-cols-16 h-10 w-full'>
        {groups.map((item: IGroup, index: number) => (
          <div
            key={index}
            className={`flex flex-col border-l border-dark cursor-pointer ${index === groups.length - 1 && 'border-r'}`}
            onClick={() => setSelectedGroup(item)}
          >
            <Text classes='h-1/2 bg-primary text-dark !text-xs flex items-center justify-center' label={(item.overload) ? item.title : ''} />
            <Text classes='h-1/2 bg-medium text-dark !text-xs flex items-center justify-center' label={gOverload(item.overload)} />
          </div>
        ))}
      </div>
      <div className='flex flex-row items-center justify-between'>
        <Button
          icon={addOutline}
          iconColorDisable={true}
          title={t('Setting.AddLC')}
          classes='border px-2 py-1 bg-primary text-white cursor-pointer'
          textClasses='text-white font-medium'
          onAction={() => handleNewLC()}
        />
        <Button
          icon={removeOutline}
          iconColorDisable={true}
          title={t('Setting.DeleteLC')}
          classes='border px-2 py-1 bg-danger text-white cursor-pointer'
          textClasses='text-white font-medium'
          onAction={() => deleteList.length > 0 && setVisibleDeleteModal(true)}
        />
      </div>
      <div className='flex flex-col rounded-md dark:bg-dark'>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          data={LCList}
        />
      </div>
      <NewLCModal
        visible={visibleAddModal}
        data={LCItem}
        onAction={handleLCAction}
        onClose={() => setVisibleAddModal(false)}
      />
      {calibrationModalMode !== CalibrationModalMode.None && <CalibrationModal
        data={LCItem}
        isConfirmModal={calibrationModalMode === CalibrationModalMode.Confirm}
        onAction={handleCalibrationAction}
        onClose={() => setVisibleCalibrationModal(CalibrationModalMode.None)}
      />}
      <DeleteConfirmModal
        visible={visibleDeleteModal}
        title={t('Msg.ConfirmDeleteTitle')}
        message={t("Msg.ConfirmDelete")}
        onAction={() => handleDeleteAction()}
        onClose={() => setVisibleDeleteModal(false)}
      />
      <SuccessModal
        visible={visibleSuccessModal}
        message={t('Msg.DeleteSuccess')}
        onClose={() => setVisibleSuccessModal(false)}
      />
      <GroupModal
        visible={selectedGroup ? true : false}
        data={selectedGroup}
        errMsg={errMsg}
        onAction={handleChangeGroup}
        onClose={() => setSelectedGroup(null)}
      />

    </CommonLayout>
  )
}

export default Settings;
