import React, { FC } from 'react';
import { ILC } from '../../helper/types';
import Text from '../Text';

interface MonitorProgProps {
  data: ILC[],
  unit: string;
}

const cellStyle = (value: string, overload: string) => {
  const val = parseFloat(value)
  const overloadVal = parseFloat(overload)
  let percent = 100;

  if (!Number.isNaN(val) && !Number.isNaN(overloadVal)) {
    percent = parseFloat((100 - val * 100 / overloadVal).toFixed(1))
  }

  console.log('precent: ', val, overloadVal, percent)
  return { height: percent + '%'}
}

const MonitorProg: FC<MonitorProgProps> = props => {
  const { data, unit } = props

  return (<div className='flex flex-wrap gap-2'>
    {data.length > 0 && data.map((item: ILC, index: number) => (
      <div key={index} className='flex flex-col w-32 border rounded'>
        <div className='flex flex-col p-1 gap-2'>
          <span className='w-full text-center text-white bg-red1'>
            0
          </span>
          <div className='flex flex-col h-24 rounded flex items-center justify-center relative'>
            <span className='z-30'>
              {item.value && parseFloat(item.value) > 0 ? `${item.value}${unit}` : '0'}
            </span>
            <div className='absolute z-20 bg-white left-0 right-0 top-0' style={cellStyle(item.value || "", item.overload || "")}>
            </div>
            <div className='absolute z-10 left-0 right-0 bottom-0 top-0 bg-gradient-to-t from-cyan1 to-red1 rounded'>
            </div>
          </div>
        </div>
        <hr />
        <div className='flex flex-col w-full bg-gray-200 px-1 py-1.5 text-center'>
          <Text type='sm-dark' classes='font-medium leading-4' label={item.title} />
          <Text type='sm-dark' classes='font-medium leading-4' label={`${item.overload}${unit}` || ''} />
        </div>
      </div> 
    ))}
  </div>)
}

export default MonitorProg;
