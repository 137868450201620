import React, { FC } from 'react';
import CommonLayout from '../Layout/CommonLayout';

const Empty: FC = () => {
  return (
    <CommonLayout title='Empty'>
      <></>
    </CommonLayout>
  )
}

export default Empty;
