import React, { FC } from 'react';
import { ILC } from '../../helper/types';
import CustomDataGrid from '../CustomDataGrid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import useAppData from '../../hooks/useAppData';

interface MonitorListProps {
  data: ILC[]
}

const MonitorList: FC<MonitorListProps> = props => {
  const { data } = props;
  const { t } = useTranslation()
  const { curProject } = useAppData();

  const columns = [
    {
      flex: 0.079,
      minWidth: 60,
      field: 'id',
      headerName: t('Monitor.List.Id'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.lc_id}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'title',
      headerName: t('Monitor.List.Name'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.title}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'value',
      headerName: t('Monitor.List.Load'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <div className={`${!row.value && 'bg-danger text-white'} px-1 py-0.5 font-medium rounded`}>
        {row.value ? row.value : 'Tr.Err'}
      </div>
    },
    {
      flex: 0.079,
      minWidth: 60,
      field: 'percent',
      headerName: '%',
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>
        {row.value && row.value !== 'Tr.Err' && row.overload ? ((parseInt(row.value) / parseInt(row.overload)) * 100).toFixed() : ''}
      </span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'battery',
      headerName: t('Monitor.List.Battery'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.battery}</span>
    },
    {
      flex: 0.105,
      minWidth: 80,
      field: 'underload',
      headerName: t('Monitor.List.Underload'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.underload}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'overload',
      headerName: t('Monitor.List.Overload'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.overload}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'sum',
      headerName: t('Monitor.List.Total'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.total_sum || 'No'}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'maximum',
      headerName: t('Monitor.List.Maximum'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.max??'0'}{parseInt(row.id) <= 10 ? curProject.windmeter_units : curProject.units}</span>
    },
    {
      flex: 0.118,
      minWidth: 90,
      field: 'groups',
      headerName: t('Monitor.List.Group'),
      // eslint-disable-next-line
      // @ts-ignore
      renderCell: ({ row }) => <span className='text.secondary'>{row.groups || ''}</span>
    },
  ];

  return (
    <div className='w-full'>
      <CustomDataGrid
        columns={columns}
        data={data}
      />
    </div>
  )
}

export default MonitorList;
