import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation,  } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { bluetoothOutline, cameraOutline, checkmarkSharp, closeSharp, duplicateOutline, imageOutline, settingsOutline, trashOutline } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { batteryBlackIcon, loadIcon, battIcon, maxIcon, maxActiveIcon, roundPictureIcon, tareIcon, tareActiveIcon, toolbarlistIcon, toolbarprogIcon, toolbarstopIcon, warningErrorIcon, bleConnectIcon, bleDisConnectIcon, batteryWhiteIcon } from '../assets/icons';
import NewProjectModal from '../components/Modals/NewProjectModal';
import { IGroup, ILC, ILog, IProject, IProjectDetail, IProofTest } from '../helper/types';
import ProjectSettingModal from '../components/Modals/ProjectSettingModal';
import { MENUS, ModalNames, ROUTES, Unit_List, Windmeter_Unit_List, LC_Serials } from '../helper/constants';
import Text from '../components/Text';
import Button from '../components/Buttons/Button';
import useAppData from '../hooks/useAppData';
import ErrorModal from '../components/Modals/ErrorModal';
import SuccessModal from '../components/Modals/SuccessModal';
import ProjectListModal from '../components/Modals/ProjectListModal';
import ProofTestModal from '../components/Modals/ProofTestModal';
import SelectDeviceModal from '../components/Modals/SelectDeviceModal';
import TotalizerModal from '../components/Modals/TotalizerModal';
import DocumentModal from '../components/Modals/DocumentModal';
import ProjectInformationModal from '../components/Modals/ProjectInformationModal';
import ProjectDeleteModal from '../components/Modals/ProjectDeleteModal';
import DeleteConfirmModal from '../components/Modals/DeleteConfirmModal';
// import * as database from '../database'
import WarningListModal from '../components/Modals/WarningListModal';
import BeforeAlertModal from '../components/Modals/BeforeAlertModal';
import { BleClient, numberToUUID } from '@capacitor-community/bluetooth-le';
import { fire_error, fire_success, hexToInt, strToFloat, strToInt, toHexString } from '../helper/functions';
import { db } from '../db';
import Swal from 'sweetalert2';
import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull, faBoltLightning, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { format, getTime, getUnixTime } from 'date-fns';
import useFunctions from '../hooks/useFunctions';
import { log } from 'console';

interface CommonLayoutProps {
  title?: string;
  classes?: string;
  children: ReactNode;
  maxStatus?: boolean;
  loadStatus?: boolean;
  tareStatus?: boolean;
  warning?: boolean;

  maxStatusToggle?: (value: boolean) => void;
  loadStatusToggle?: (value: boolean) => void;
  tareStatusToggle?: () => void;
  onWarning?: (value: boolean) => void;
  onDBHandler?: (handler: any) => void;
  onTareAction?: (type: string, group_id: string) => void;
}

const CommonLayout: FC<CommonLayoutProps> = props => {
  const {
    title = '',
    classes = '',
    children,
    maxStatus: max = false,
    loadStatus: load = true,
    tareStatus = false,
    // eslint-disable-next-line
    maxStatusToggle = () => { },
    // eslint-disable-next-line
    loadStatusToggle = () => { },
    // eslint-disable-next-line
    tareStatusToggle = () => { },
    // eslint-disable-next-line
    onWarning = () => { },
    // eslint-disable-next-line
    onDBHandler = () => { },
    // eslint-disable-next-line
    onTareAction = () => { },
  } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const {
    mode,
    errStr,
    successStr,
    monitorStatus,
    projects,
    curProject,
    lcs,
    logs,
    groups,
    visibleModal,
    bleConnected,
    batteryStatus,
    totalWeightHtml,
    warnList,
    platformType,

    updateErrStr,
    updateSuccessStr,
    updateMonitorStatus,
    updateProjects,
    updateCurProject,
    updateLCs,
    updateGroups,
    updateLogs,
    updateVisibleModal,
    updateBleConnected,
    updateWarnList,
    updateLiveLC,
    updateBatteryStatus,
    updateLCWindAlerts,
  } = useAppData()

  const navigate = useLocation()
  const {
    f_use_insert_project,
    f_use_update_project_setting,
    f_update_project_last_change,
    // f_update_project_settings,
    f_convert_wind_speed,
    f_check_lc_in_project,
    f_lc_capacity_id,
    f_lc_by_id,
    f_log_lc_value,
    f_log_delete,
    f_load_cells,
    f_insert_lc,
  } = useFunctions()

  const [dbHanlder, setDBHandler] = useState(null)
  const theme = useTheme();

  const [projectList, setProjectList] = useState<IProject[]>([])
  const [active_project, setSelectProject] = useState<IProject>({} as IProject);
  const [groupList, setGroupList] = useState<IGroup[]>([])

  const [visibleBeforeAlert, setVisibleBeforeAlert] = useState<boolean>(false);
  const [visibleNew, setVisibleNew] = useState<boolean>(false);
  const [visibleSetting, setVisibleSetting] = useState<boolean>(false);
  const [duplicated, setDuplicated] = useState<boolean>(false);
  const [confirmTitle, setConfirmTitle] = useState<string>('');

  const [visibleProof, setVisibleProof] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IProofTest | null>(null)
  const [connected, setConnected] = useState<boolean>(false);

  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [deleteProject, setDeleteProject] = useState<string>('');
  const [warnLogs, setWarnLogs] = useState<any[]>([]);

  // const [lc_tare, setTare] = useState(false)
  // const [lc_battery, setBattery] = useState(false)
  const [lc_wind_alerts, setWindAlerts] = useState<any[]>([])
  const [lc_alerts, setAlerts] = useState<any[]>([])
  // const [danger_state, setDangerState] = useState(false)

  const [btry, setBtry] = useState(0)
  const [batteryIcon, setBatteryIcon] = useState<IconDefinition>(faBatteryEmpty)
  const [cycleStatus, setCycleStatus] = useState<boolean>(false)

  const { f_log_prooftest_value } = useFunctions()

  useEffect(() => {
    setWarnLogs(warnList)
  }, [warnList])

  const handleChangeGroup = (group: IProofTest) => {
    console.log("123");
  }

  useEffect(() => {
    if (curProject && curProject.cycle) {
      if (!cycleStatus) {
        setCycleStatus(true)
        setTimeout(() => {
          f_log_delete()
          setCycleStatus(false)
        }, 60 * 60 * 1000)
      }
    } else setCycleStatus(false)
  }, [curProject, cycleStatus])

  const fire_overwind: any = (lc: string, weight: string, overload: string) => {
    console.log('===fire_overwind===')

    const fx = 2;
    const windAlerts = lc_wind_alerts;
    windAlerts.forEach((alert: any, index: number) => {
      if (alert.id === lc) {
        const c = new Date().getTime();
        const d = getTime(alert.last_alert);
        if ((c - d) > 40000) {
          onWarning(true);
          Swal.fire({
            title: '',
            html: "<h2 class='bg-danger text-white'>DANGER - WIND SPEED!</h2><h2 class='bg-danger text-white'>Windmeter: " + lc + "</h2><h2 class='bg-danger text-white'>Speed: " + parseFloat(weight).toFixed(fx) + " | Capacity: " + parseFloat(overload).toFixed(fx) + "</h2>"
          });
          // navigator.notification.beep(4);
          windAlerts[index].last_alert = new Date().getTime();
        }
      }
    })

    updateLCWindAlerts(windAlerts)
  }

  const handleReadingByProject = async (db: any, project_id: string) => {
    // const lcData = await database.lcs.findByProjectId(db, project_id)
    // updateLCs(lcData.map((item: any) => item._data))

    // const groupData = await database.groups.findByProjectId(db, project_id)
    // updateGroups(groupData.map((item: any) => item._data))
  }

  const load_projects = async () => {
    const projectData = await db.projects.toArray();
    console.log("projectData++", projectData)
    if (projectData.length > 0) {
      updateProjects(projectData)
      updateCurProject(projectData[0])
      load_lcs()
      load_groups()
    } else {
      setVisibleNew(true)
    }
  }
  const handleDuplicatLc = async(prevId:string,newId:string) =>{
    lcs.map((lc)=>{
    if(lc.project_id===prevId) {
      const newLC={ ...lc,project_id:newId };
      f_insert_lc(newLC)
    }})
}
  const project_groups = () => {
    console.log('===project_groups===')
  }

  const clear_groups = () => {
    console.log('===clear_groups===')
  }

  const zero_group = () => {
    console.log('===zero_group===')
  }

  const save_group = () => {
    console.log('===save_group===')
  }

  const load_lcs = async () => {
    if (!curProject.id) return
    const myLcsStore = db.lcs;
    const key = 'project_id';
    const value = curProject.id;
    const _lcs: any = [];
    myLcsStore.where(key).equals(value).toArray().then(function (data) {
      // clear_lc_table();
      // clear_lc_monitor();
      data.forEach(function (item) {
        _lcs.push(item)
        // draw_lc_row(item, data.length);
        // draw_lc_monitor(item);
        // draw_lc_group(item);
        if (item.id <= 10) {
          lc_wind_alerts.push({ id: item.id, last_alert: 0 });
        } else {
          lc_alerts.push({ id: item.id, last_alert: 0 });
        }
      });
      updateLCs(_lcs);
      // monitor_dragdrop();
      if (active_project.show_graphs) {
        console.log('draw_chart')
        // draw_chart('lightChart', active_project.lcs);
      }
    }).catch(function (error) {
      console.error('Error querying data from the table: ' + error);
    });
  }

  const load_groups = async () => {
    if (!curProject.id) return
    const myGroups = db.groups;
    // Query data based on a key-value pair (e.g., name equals 'John Doe')
    const key = 'project_id';
    const value = parseInt(curProject.id);
    myGroups.where(key).equals(value).toArray().then(function (data) {
      // 'data' is an array containing items that match the key-value pair
      updateGroups(data)
    }).catch(function (error) {
      console.error('Error querying data from the table: ' + error);
    });
  }

  const draw_lc_group = () => {
    console.log("===draw_lc_group===")
  }

  const lc_refresh = () => {
    console.log("===lc_refresh===")
  }



  useEffect(() => {
    // database.init()
    //   .then(async (res: any) => {
    //     setDBHandler(res)
    //     onDBHandler(res)

    //     const projectData = await database.projects.find(res)
    //     if (projectData.length > 0) {
    //       const list = projectData.map((item: any) => item._data)
    //       updateProjects(list)
    //       updateCurProject(list[0])

    //       await handleReadingByProject(res, list[0].id)
    //     } else {
    //       history.replace(ROUTES.Settings)
    //       setVisibleNew(true)
    //     }
    //   })

    load_projects()
  }, [])

  useEffect(() => {
    load_lcs()
    load_groups()
  }, [curProject])

  useEffect(() => {
    setProjectList(projects)
  }, [projects])

  useEffect(() => {
    setGroupList(groups)
  }, [groups])

  // useEffect(() => {
  //   if (logs.length > 0) {
  //     const filtered = logs.filter(item => item.value && item.overload && item.underload && (item.value > item.overload || item.value < item.overload * (1 + item.underload / 1000)))
  //     setWarnLogs(filtered)
  //   } else
  //     setWarnLogs([])
  // }, [logs])

  useEffect(() => {
    setSelectProject(curProject)
  }, [curProject])

  useEffect(() => {
    if (visibleModal === ModalNames.NewProject) {
      setVisibleNew(true)
    }
  }, [visibleModal])

  useEffect(() => {
    setConnected(bleConnected)
  }, [bleConnected])

  const handleShowNewProject = () => {
    setVisibleNew(true)
    updateVisibleModal('')
  }

  const insert_project = async (project_name: string, unit_type = 'KG') => {
    let proj_name_exists = false;
    projectList.forEach(function (proj: any) {
      if (proj.title == project_name) {
        fire_error(project_name + " already exists. please select unique project name");
        proj_name_exists = true;
      }
    });
    if (proj_name_exists) {
      return;
    }
    let newData: any;
    if (duplicated){
      newData = { title: project_name, units: active_project.units, last_settings_change: getTime(new Date()), pre_overload: active_project.pre_overload, windmeter_units: active_project.windmeter_units };
      
    }else
      newData = { title: project_name, units: unit_type, last_settings_change: getTime(new Date()), pre_overload: 100, windmeter_units: 'MS' };
    const res: string = await f_use_insert_project(newData);

    if (res!='') {
      if (duplicated) {
        await handleDuplicatLc(active_project.id,res);
        fire_success('Project Created');
        setVisibleNew(false)
        setDuplicated(false)
        console.log('!!!!!!!!!!!!curProject');
        
      }
      else{
      setTimeout(function () {
        setVisibleNew(false)
        setVisibleSetting(true)
      }, 500)
    }
    } else {
      console.error('Error adding data to the table');
    }
  }

  const update_units = () => {
    console.log('===update_units===')
  }

  const handleCloseNewProject = () => {
    if (projectList.length > 0)
      setVisibleNew(false)
  }

  const conversionFactors: any = {
    'KMH': 3.6,          // 1 m/s = 3.6 km/h
    'MLH': 2.23694,      // 1 m/s = 2.23694 mph
    'FS': 3.28084,       // 1 m/s = 3.28084 ft/s
    'MS': 1              // 1 m/s = 1 m/s
  };
  const convertUnitsFactors: any = {
    'KG': 1,          // 1 kg = 1kg
    'LBS': 2.20462,      // 1 kg = 2.20462lbs 
    'M.TON': 0.001,       // 1 kg = 0.001ton
  }

  const get_units_multiply = (prevUnit: any, currentUnit: any) => {
    return convertUnitsFactors[currentUnit] / convertUnitsFactors[prevUnit]
  }
  const convert_wind_speed = (prevUnit: any, currentUnit: any) => {
    return conversionFactors[currentUnit] / conversionFactors[prevUnit];
  }

  const handleSettingProject = async (project: IProject) => {
    if (!project.total_overload) {
      updateErrStr(t("Msg.ErrTotalOverload"))
      return;
    }
    if (!project.pre_overload) {
      updateErrStr(t("Msg.ErrPreoverload"))
      return;
    }
    // curProject.units
    const multiply = (curProject.units !== project.units) ? get_units_multiply(curProject.units, project.units) : 1;
    // project = { ...project, total_overload: (parseFloat(project.total_overload) * (multiply)).toString() }
    const weight = (curProject.windmeter_units !== project.windmeter_units) ? convert_wind_speed(curProject.windmeter_units, project.windmeter_units) : 1;
    const { id, ...rest } = project;    
    f_use_update_project_setting(id, rest, multiply, weight)
    // updateProjects(project);
    // await db.projects.put(project);
    setVisibleSetting(false);
    updateSuccessStr(t("Msg.ConfirmSetting"));
  }

  const handlePdfAction = () => {
    updateVisibleModal(ModalNames.ProjectInformation)
  }

  const duplicate_project = () => {
    setDuplicated(true)
    setVisibleNew(true)
  }

  const get_project = async (id: string) => {
    const project = projectList.find(item => item.id === id)
    if (project) {
      updateCurProject(project)
      await handleReadingByProject(dbHanlder, project.id)

      updateVisibleModal('')
    }
  }

  const monitorIcon = () => {
    switch (monitorStatus) {
      case 'view':
        return roundPictureIcon;
      case 'list':
        return toolbarlistIcon;
      case 'prog':
        return toolbarprogIcon;
      case 'stop':
        return toolbarstopIcon;
      default:
        break;
    }
  }

  const handleSelectDevice = (type: string) => {
    console.log('selected device: ', type)
    switch (type) {
      case 'prr':
      case 'lc':
        bt_scan(type)
        break;
      case 'usb':
        usb_scan()
        break;
      default:
        break;
    }
    handleCloseModal()
  }

  const bt_scan = async (type: string) => {
    // no device paired yet
    const s = (type == "prr") ? numberToUUID(0xfff0) : '0bd51666-e7cb-469b-8e4d-2742f1ba77cc';
    const c = (type == "prr") ? numberToUUID(0xfff4) : 'e7add780-b042-4876-aae1-112855353cc1';
    try {
      await BleClient.initialize();

      const device = await BleClient.requestDevice({
        services: [s],
        optionalServices: []
      });

      console.log('')
      //  connect to device, the bt_disconnect callback is optional
      console.log('device info: ', device)
      await BleClient.connect(device.deviceId, (deviceId) => bt_disconnect(deviceId));
      console.log('connected to device', device);
      updateBleConnected(true);

      const services = await BleClient.getServices(device.deviceId);
      console.log('services: ', services)

      await BleClient.startNotifications(
        device.deviceId,
        services[0].uuid,
        services[0].characteristics[0].uuid,
        (value) => {
          bt_parse(new Uint8Array(value.buffer));
          console.log('bluetooth value: ', value)
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const bt_connect = () => {
    console.log('===bt_connect===')
  }

  const bt_parse = (a: Uint8Array) => {
    const typ = toHexString([a[2]]);
    if (a[2] === 55) {
      console.log('a[2] === 55')
    }
    if (typ === 'bb' || typ == 'bc') {
      const btry = parseInt('0x' + toHexString([a[10]]), 16);
      setBtry(btry);

      // prr voltage - not charging
      if (typ === 'bb') {

        if (btry < 11) {
          setBatteryIcon(faBatteryEmpty)
        } else if (btry >= 11 && btry < 25) {
          setBatteryIcon(faBatteryQuarter)
        } else if (btry >= 25 && btry < 50) {
          setBatteryIcon(faBatteryHalf)
        } else if (btry >= 50 && btry < 90) {
          setBatteryIcon(faBatteryThreeQuarters)
        } else if (btry >= 90) {
          setBatteryIcon(faBatteryFull)
        }
      } else {
        setBatteryIcon(faBoltLightning)
      }
    } else if (a[2] <= 10) {
      const lc = parseInt('0x' + toHexString([a[3], a[4], a[5]]), 16);
      if (!f_check_lc_in_project(lc.toString())) {
        return;
      }

      let realval: any = hexToInt(toHexString([a[6], a[7], a[8], a[9]])) / 10000; // mt.TON
      let weight: any = hexToInt(toHexString([a[6], a[7], a[8], a[9]])) / 10000; // mt.TON

      let lc_btry;
      if (a[2] == 55) {
        if (a[2] == 55 && a[10].toString(2) == "11111111") {
          lc_btry = 100;
        } else {
          lc_btry = parseInt(a[10].toString(2).substr(a[10].toString(2).length - 3));
        }
      } else {
        lc_btry = parseInt('0x' + toHexString([a[10]]), 16);
      }

      const lcItem = lcs.find(item => item.id === lc.toString())
      if (lcItem) {
        updateLCs({ ...lcItem, battery: lc_btry.toString() })
      }
      updateBatteryStatus(lc_btry)
      if (lc_btry > 100) {
        lc_btry = 100;
      }

      if (lc_btry <= 20) {
        const last_val = parseInt('0');
      }

      const capacity: any = f_lc_capacity_id(lc);

      if (lc > 10 && weight > capacity.mton * 2) {
        // something disturbed, number wrong 
        return;
      }

      const u = active_project.units?.toLowerCase();

      if (lc > 10) {
        if (u == "lbs") {
          weight = (weight * 2204.62).toFixed(0);
          realval = (realval * 2204.62).toFixed(0);
        } else if (u == "kg") {
          weight = (weight * 1000).toFixed(0);
          realval = (realval * 1000).toFixed(0);
        } else {
          weight = parseFloat(weight).toFixed(3);
          realval = parseFloat(realval).toFixed(3);
        }
      } else {
        weight = parseFloat(weight).toFixed(3);
        realval = parseFloat(realval).toFixed(3);
      }

      const l = f_lc_by_id(lc) as ILC

      const calibrationOffset = parseFloat(l.calibration_offset ?? '1')
      const psw = parseFloat(l.psw ?? '0')
      const overload = parseFloat(l.overload ?? '0')
      const underload = parseFloat(l.underload ?? '0')
      const tare = l.tare ?? 0
      const zero = l.zero ?? 0

      const lc_battery: any = false;

      if (parseInt(lc + '') >= 1 && parseInt(lc + '') <= 10) {
        // realval = realval/100;
        // weight = weight/100;
        const speedInMetersPerSecond = realval;
        const targetUnit = active_project.windmeter_units;
        const weight: number = f_convert_wind_speed(speedInMetersPerSecond, targetUnit);
        f_log_lc_value(lc, active_project.id, weight, realval, overload, underload);
        console.log('realval log', realval, weight);
        updateLCs({ id: lc + '', value: realval } as ILC)

        if (weight > f_convert_wind_speed(overload, targetUnit)) {
          fire_overwind(lc, weight, f_convert_wind_speed(overload, targetUnit), 1)
        } else {
          console.log('else wieght > convert_wind_speed(...)');
        }

        if (lc_battery) {
          console.log('battery wind meter');
        } else if (!max) {
          console.log('lc_max is false');
        }
        draw_chart_line(lc, weight);
      } else {

        weight = weight / calibrationOffset;
        realval = realval / calibrationOffset;
        let weightnotare;
        const lc_tare = false;
        if (lc_tare) {
          weightnotare = weight + zero;
          weight = weight + zero - tare;
        } else {
          weightnotare = weight + zero;
          weight = weight + zero;
        }

        if (isNaN(psw)) {
          l.psw = '0';
        }

        let wpsw: any = parseFloat(weight) + psw;
        let p: any = (wpsw / overload) * 100;
        p = (u == "lbs" || u == "kg") ? p.toFixed(0) : p.toFixed(3);
        wpsw = (u == "lbs" || u == "kg") ? wpsw.toFixed(0) : wpsw.toFixed(3);
        
        const liveLCItem = lcs.find(item => item.id === lc.toString())
        if (liveLCItem) 
          updateLiveLC({ ...liveLCItem, realval, value: wpsw, time: format(new Date(), 'pp'), max: l.max && parseFloat(l.max) >= 0 ? (realval >= 0 ? (realval > parseFloat(l.max) ? realval : l.max) : 0) : (realval >= 0 ? realval : 0)
          })
        updateLCs(lcs.map(item =>
          item.id === lc.toString() ?
            ({ ...item, value: wpsw, realval })
            :
            ({ ...item, value: 'Tr.Err' })
        ))

        if (!max) {
          console.log('!max')
        }

        // m = parseInt($("#monitor_table tr[data-id='" + lc + "'] [data-max] m").first().text());
        const m = 0;
        if (wpsw > m) {
          console.log('wpsw > m')
        }

        if (l.psw) {
          weight = weight + parseFloat(l.psw);
          weightnotare = weightnotare + parseFloat(l.psw);
        }

        weight = parseFloat(weight);
        weightnotare = parseFloat(weightnotare);
        weight = (u != "m.ton") ? weight.toFixed(0) : weight.toFixed(3);
        const weighttolog = (u != "m.ton") ? weightnotare.toFixed(0) : weightnotare.toFixed(3);
        let preoverload_precent = 0;
        const pre_overload = parseInt(active_project.pre_overload ?? '0')
        if (pre_overload > 0) {
          const po = pre_overload / 100
          preoverload_precent = overload * po;
        }

        if (weightnotare < underload) {
          // underload!! alert
          // we must quit max mode and show the danger
          maxStatusToggle(false)
          fire_underload(lc, weight, underload);
          updateWarnList({
            log_date: format(new Date(), "HH:mm"),
            title: (l.title) ? l.title : l.id,
            value: weighttolog,
            underload: l.underload,
            overload: l.overload,
          })
        } else if (weightnotare > overload) {
          // overload!! alert
          // we must quit max mode and show the danger
          maxStatusToggle(false)

          const danger = (((weight / overload) * 100) > 129) ? true : false;
          updateWarnList({
            log_date: format(new Date(), "HH:mm"),
            title: (l.title) ? l.title : l.id,
            value: weighttolog,
            underload: l.underload,
            overload: l.overload,
          })
          fire_overload(lc, weight, l.overload, danger);
        } else if (weightnotare > preoverload_precent) {
          updateWarnList({
            log_date: format(new Date(), "HH:mm"),
            title: (l.title) ? l.title : l.id,
            value: weighttolog,
            underload: l.underload,
            overload: l.overload,
          })
          console.log('weightnotare > preoverload_precent')
        } else {
          if (!max) {
            if (!lc_battery && !lc_tare) {
              console.log('!lc_battery && !lc_tare')
            } else if (lc_tare) {
              // const grp = parseInt(l.groups);
              // if (typeof active_project.groups[grp] != "undefined" && active_project.groups[grp].tare === 1) {
              //   console.log('typeof active_project.groups[grp] != "undefined" && active_project.groups[grp].tare === 1')
              // } else {
              //   console.log('else typeof active_project.groups[grp] != "undefined" && active_project.groups[grp].tare === 1')
              // }
            } else if (lc_battery) {
              console.log('lc_battery')
            } else {
              console.log('else lc_battery')
            }
          }

          if (a[2] == 55 && parseInt(a[10] + '').toString(2).length == 8) {
            weight = weight - tare;
            weightnotare = weightnotare - tare;
          } else {
            console.log("else a[2] == 55 && parseInt(a[10]+'').toString(2).length == 8")
          }
        }

        if (a[2] == 55 && a[10].toString(2) == "11111111") {
          const tare = weight;
          onTareAction('tare', '0');
          return;
        }

        if (a[2] == 55 && parseInt(a[10] + '').toString(2).length == 7) {
          onTareAction('untare', '0');
        }

        f_log_lc_value(lc, active_project.id, weight, realval, l.overload, l.underload);
        console.log('realval log', realval, weight);
        draw_chart_line(lc, weight);

        // for calibration process

        const in_group = lcs.find((x: any) => x.id == lc);
        const proof_test = {
          status: 1,
          selected_group: '',
          last_read: 1,
          proof_chart: {
            data: {
              datasets: [
                {
                  data: ['none']
                }
              ],
              labels: [getTime(new Date())]
            },
            update: () => {
              console.log('chart update')
            }
          }
        }
        if (proof_test.status == 1 && in_group?.groups?.split(',').includes(proof_test.selected_group)) {
          f_log_prooftest_value(lc, active_project.id, weight, realval, l.overload, l.underload, proof_test.selected_group);
          proof_test.last_read = getUnixTime(new Date());
          // $("#proof_current_read").val(weight);
          const chart = proof_test.proof_chart;
          if (chart.data.datasets.length > 0) {
            const s = getTime(new Date());
            if (!chart.data.labels.includes(s)) {
              chart.data.labels.push(s);
            }
            chart.data.datasets.forEach((item, i) => {
              chart.data.datasets[i].data.push(weight);
              chart.update();
            });
          }
        }
      }
    }
  }

  const bt_exponentialBackoff = (max: any, delay: any, toTry: any, success: any, fail: any) => {
    console.log('===bt_exponentialBackoff===')
    toTry().then((result: any) => success(result)).catch((_: any) => {
      // device.addEventListener('gattserverdisconnected', onBtDisconnected);

      if (max === 0) {
        return fail();
      }
      setTimeout(function () {
        bt_exponentialBackoff(--max, delay * 2, toTry, success, fail);
      }, delay * 1000);
    });
  }



  const fire_overload = (lc: number, weight: any, overload: any, danger: any) => {
    console.log('fire_overload', lc, weight, overload, danger)
    const u = curProject.units?.toLowerCase().replace('.', '') //for m.ton;
    const fx = (u == "mton") ? 3 : 0;
    lc_alerts.forEach((item: any, index: number) => {
      if (item.id == lc) {
        const c = new Date().getTime();
        const d = new Date(item.last_alert).getTime();
        if ((c - d) > 40000) {
          // $(".warning").addClass('active');
          Swal.fire({
            title: '',
            html: "<h2 class='bg-danger text-white'>DANGER - WIND SPEED!</h2><h2 class='bg-danger text-white'>Windmeter: " + lc + "</h2><h2 class='bg-danger text-white'>Speed: " + parseFloat(weight).toFixed(fx) + " | Capacity: " + parseFloat(overload).toFixed(fx) + "</h2>",
            heightAuto: false,
          });
          // navigator.notification.beep(4);
          lc_wind_alerts[index].last_alert = new Date().getTime()
        }
      }
    })
  }

  const fire_underload = (lc: number, weight: any, underload: any) => {
    console.log('fire_underload ', lc, weight, underload)
    const u = curProject.units?.toLowerCase().replace('.', '') //for m.ton;
    const fx = (u == "mton") ? 3 : 0;
    lc_alerts.forEach((item: any, index: number) => {
      if (item.id == lc) {
        const c = new Date().getTime();
        const d = new Date(item.last_alert).getTime();
        const o = "UNDERLOAD";

        if ((c - d) > 20000) {
          // $(".warning").addClass('active');
          Swal.fire({
            title: '',
            html: "<h2 class='bg-danger text-white'>" + o + "!</h2><h2 class='bg-danger text-white'>LC: " + lc + "</h2><h2 class='bg-danger text-white'>Weight: " + parseFloat(weight).toFixed(fx) + " | Underload: " + parseFloat(underload).toFixed(fx) + "</h2>",
            heightAuto: false,
          });
          // navigator.notification.beep(4);
          lc_alerts[index].last_alert = new Date().getTime()
        }
      }
    })
  }

  const draw_chart_line = (lc: number, weight: any) => {
    console.log('draw chat line ', lc, weight)
  }

  function bt_disconnect(deviceId: string): void {
    console.log(`device ${deviceId} disconnected`);
  }

  const usb_scan = () => {
    console.log('')
  }

  const bt_auto_reconnect = () => {
    console.log('===bt_auto_reconnect===')
  }

  const bt_read = async (device_id: any, service_uuid: any, characteristic_uuid: any) => {
    try {
      const res = await BleClient.read(device_id, service_uuid, characteristic_uuid);
      console.log(JSON.stringify(new Uint8Array(res.buffer)));
    } catch (err) {
      console.log('Read BT data failed')
    }
  }

  const bt_browser_notify = (event: any) => {
    const value = event.target.value.buffer;
    const a = new Uint8Array(value);
    bt_notify(a, "", "")
  }

  const bt_notify = async (device_id: any, service_uuid: any, characteristic_uuid: any) => {
    if (platformType === 'web') {
      bt_parse(device_id)
    } else {
      BleClient.startNotifications(device_id, service_uuid, characteristic_uuid, function (buffer: any) {
        const a = new Uint8Array(buffer);
        bt_parse(a);
      });
    }
  }

  const onBtDisconnected = () => {
    console.log('===onBtDisconnected===')
    console.log('Device disconnected', event);
    // Optionally, try to reconnect
    // connectToDevice();
  }

  const sum_overload = () => {
    console.log('===sum_overload===')
    fire_error('Total Overload must be higher than 0');
  }

  const toggle_sidebar = () => {
    console.log('===toggle_sidebar===')
    // $('#sidebar').toggleClass('active');
  }

  const show_sidebar = () => {
    console.log('===show_sidebar===')
    // $('#sidebar').addClass('active');
  }

  const hide_sidebar = () => {
    console.log('===hide_sidebar===')
    // $('#sidebar').removeClass('active');
  }

  const handleCloseModal = () => {
    updateVisibleModal('')
  }

  const handleSuccessClose = () => {
    updateSuccessStr('')
    setConfirmTitle('')
  }

  const hanldeImgLoad = async (type: string) => {
    try {
      if (type === 'camera') {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          saveToGallery: true
        })

        console.log('image result: ', image)
      } else {
        const image = await Camera.getPhoto({
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: CameraSource.Photos
        })

        console.log('image reuslt: ', image)
      }
    } catch (error) {
      console.log('image result error: ', error)
    }
  }

  const imageUrlToBase64 = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  const verify_settings = () => {
    if (curProject === null) {
      return;
    }
    let result = { verify: "ok", group: null }
    groups.forEach(function (group: any) {
      if (parseInt(group.group_id) > 0) {
        if (group.overload === 0) {
          result = { verify: "fail", group: group.group_id }
        }
      }
    });
    let found_lc_sum = false;
    lcs.forEach(function (lc: any) {
      if (lc.total_sum == 1) {
        found_lc_sum = true;
      }
    });

    if (!found_lc_sum) {
      result = { verify: "nosum", group: null }
    }

    // $("#settings .grp_h .bg-warning").each(function (a: any, b: any) {
    //   if ($("span", b).text() == 0) {
    //     result = { verify: "fail", group: a + 1 }
    //   }
    // });

    if (curProject.total_overload === null || curProject.total_overload === '0') {
      result = { verify: "nototaloverload", group: null }
    }

    return result;
  }

  const projectInformationAction = async (url: string) => {
    const imageBase64 = await imageUrlToBase64(url);
    const updatedProject = { ...active_project, p_image: imageBase64 as string }
    updateCurProject(updatedProject)
    updateProjects(updatedProject);
    // database.projects.upsert(dbHanlder, updatedProject)

    const projectsStore = db.projects;

    projectsStore.put(updatedProject).then(res => {
      console.log('update image success: ', res)
    });

    updateSuccessStr(t('Msg.ConfirmHeader'))
    setConfirmTitle(t('Common.Confirm'))
  }

  const delete_project = () => {
    const project = projects.find(item => item.id === deleteProject)
    if (project) {
      updateLCs(lcs.filter(item => item.project_id !== project.id))
      updateProjects(projects.filter(item => item.id !== deleteProject))

      // database.deleteProject(dbHanlder, deleteProject)
      setDeleteProject('')

      const projectsTable = db.projects;
      const lcsTable = db.lcs;

      db.transaction('rw', projectsTable, lcsTable, function () {
        projectsTable
          .where('id')
          .equals(parseInt(project.id))
          .delete()
          .then(function () {
            console.log('Project deleted successfully');
            return lcsTable.where('project_id').equals(parseInt(project.id)).delete();
          })
          .then(function () {
            console.log('Associated LCS records deleted successfully');
          })
          .catch(function (error) {
            console.error('Error deleting project: ' + error);
          });
      }).then(function () {
        // Successfully completed the transaction
        load_projects();
      }).catch(function (error) {
        console.error('Transaction error: ' + error);
      });
    }
  }

  const handleAlertClear = async () => {
    if (warnLogs.length > 0) {
      const warnIds = warnLogs.map(item => item.id)
      updateLogs(logs.filter(item => !warnIds.includes(item.id)))
      // await database.logs.bulkRemove(dbHanlder, warnIds)
    }
  }

  const isDark = useMemo(() => mode === 'dark', [mode])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='dark' />
          </IonButtons>
          {location.pathname === ROUTES.Monitor &&
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row items-center gap-4'>
                <div className='flex flex-col'>
                  <IonLabel color='dark'>{t("Monitor.Header.TotalWeight")}</IonLabel>
                  {bleConnected ?
                    <div className='text-black dark:text-white' dangerouslySetInnerHTML={{ __html: totalWeightHtml }} />
                    :
                    <span className="text-xs font-bold bg-danger text-white px-1.5 py-0.5 rounded w-max">{t("Common.TrErr")}</span>
                  }
                </div>
                <div className='flex flex-col'>
                  <IonLabel color='dark' className='text-right -mb-1'>{`PRR ${connected ? batteryStatus : ''}`}</IonLabel>
                  {/* <IonImg src={isDark ? batteryBlackIcon : batteryWhiteIcon} alt='battery' className='h-8 -mb-2' /> */}
                  <FontAwesomeIcon icon={batteryIcon} size='2x' color={`${isDark ? 'black' : 'white'}`} />
                </div>
                <div className='relative flex items-center justify-center'>
                  <IonImg src={connected ? bleConnectIcon : bleDisConnectIcon} alt='ble' className='w-10' />
                </div>
              </div>
              {active_project.id && <Text
                label={`${active_project.title} - ${t('Monitor.Header.Title')} | ${t('Monitor.Header.Units')}: ${active_project.units} | ${active_project.windmeter_units}`}
              />}
              <div className='flex flex-row items-end gap-2 pr-2'>
                <IonImg
                  src={warningErrorIcon}
                  className='h-10 w-10 cursor-pointer'
                  onClick={() => updateVisibleModal(ModalNames.WarningList)}
                />
                <IonImg
                  src={monitorIcon()}
                  className='h-10 w-10 cursor-pointer'
                  onClick={() => updateMonitorStatus()}
                />
                <IonImg src={max ? maxActiveIcon : maxIcon} className='h-10 w-10 cursor-pointer' onClick={() => maxStatusToggle(!max)} />
                <IonImg src={tareStatus ? tareActiveIcon : tareIcon} className='h-10 w-10 cursor-pointer' onClick={() => tareStatusToggle()} />
                <IonImg src={load ? loadIcon : battIcon} className='h-10 w-10 cursor-pointer' onClick={() => loadStatusToggle(!load)} />
              </div>
              {/* <IonTitle>{title || "Projects"}</IonTitle> */}
            </div>
          }
          {location.pathname === ROUTES.Settings && active_project.id &&
          <div>
              {/* <div>Project: {active_project?.title} </div> */}
            <div className='flex items-center justify-end gap-1.5 px-2 overflow-auto'>
              <div className='flex flex-row gap-1.5'>
                <Text label={`${t('Project.Units')}: `} />
                <Text label={Unit_List.find(item => item.value === active_project.units)?.title || ''} />
                <Text label='|' />
                <Text label={Windmeter_Unit_List.find(item => item.value === active_project.windmeter_units)?.short || ''} />
                <Text label='|' />
              </div>
              <div className='flex flex-row gap-1.5'>
                <Text label={`${t('Common.Projects')}: ${active_project?.title}`} />
                <Button
                  title={t('Common.Settings')}
                  icon={settingsOutline}
                  onAction={() => setVisibleSetting(true)}
                />
                <Button
                  title={t('Common.Duplicate')}
                  icon={duplicateOutline}
                  onAction={() => duplicate_project()}
                />
                <Button
                  title={t('Common.Delete')}
                  icon={trashOutline}
                  onAction={() => setVisibleDelete(true)}
                />
              </div>
              <div className='flex flex-row items-center gap-1'>
                <Text label={`${t('Common.Image')}:`} />
                <IonIcon
                  color='dark'
                  icon={imageOutline}
                  className='text-xl cursor-pointer'
                  onClick={() => hanldeImgLoad('gallery')}
                />
              </div>
              <IonIcon
                color='dark'
                icon={cameraOutline}
                className='text-2xl cursor-pointer'
                onClick={() => hanldeImgLoad('camera')}
              />
            </div>
            
          </div>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {title && <IonLabel>{title}</IonLabel>}
        <div className={`flex flex-col ${classes}`}>
          {children}
        </div>
      </IonContent>
      <ProjectListModal      
        visible={visibleModal === MENUS.Projects}
        data={projectList}
        onSelect={get_project}
        onAction={() => handleShowNewProject()}
        onClose={() => updateVisibleModal('')}
      />
      <BeforeAlertModal
        visible={visibleBeforeAlert}
        onAction={() => setVisibleBeforeAlert(false)}
        onClose={() => setVisibleBeforeAlert(false)}
      />
      <NewProjectModal
        visible={visibleNew}
        dismiss={projectList.length > 0}
        onAction={insert_project}
        onClose={() => handleCloseNewProject()}
      />
      <ProjectSettingModal
        visible={visibleSetting}
        data={active_project}
        onAction={handleSettingProject}
        onClose={() => setVisibleSetting(false)}
        onPdfAction={() => handlePdfAction()}
      />
      <ErrorModal
        visible={errStr ? true : false}
        message={errStr}
        onClose={() => updateErrStr('')}
      />
      <SuccessModal
        visible={successStr ? true : false}
        title={confirmTitle}
        message={successStr}
        onClose={() => handleSuccessClose()}
      />
      <ProofTestModal
        visible={visibleModal === MENUS.ProofTest}
        groupList={groupList.filter(item => item.overload)}
        lcList={lcs.filter(item => item.groups)}
        unit={curProject.units || ''}
        data={selectedGroup}
        onClose={() => handleCloseModal()}
      />
      <SelectDeviceModal
        visible={visibleModal === MENUS.ConnectDevice}
        onAction={handleSelectDevice}
        onClose={() => handleCloseModal()}
      />
      <TotalizerModal
        visible={visibleModal === MENUS.Totalizer}
        groups={groups}
        lcs={lcs}
        onAction={() => handleCloseModal()}
        onClose={() => handleCloseModal()}
      />
      <DocumentModal
        visible={visibleModal === MENUS.Document}
        groups={groups}
        lcs={lcs}
        onAction={() => handleCloseModal()}
        onClose={() => handleCloseModal()}
      />
      <ProjectInformationModal
        visible={visibleModal === ModalNames.ProjectInformation}
        onAction={projectInformationAction}
        onClose={() => handleCloseModal()}
      />
      <ProjectDeleteModal
        visible={visibleDelete}
        data={projectList}
        onSelect={setDeleteProject}
        onClose={() => setVisibleDelete(false)}
      />
      <DeleteConfirmModal
        visible={deleteProject ? true : false}
        message={t('Msg.ConfirmDeleteProject')}
        onAction={() => delete_project()}
        onClose={() => setDeleteProject('')}
      />
      <WarningListModal
        visible={visibleModal === ModalNames.WarningList}
        data={warnLogs}
        onClear={() => handleAlertClear()}
        onClose={() => handleCloseModal()}
      />
    </IonPage>
  )
}

export default CommonLayout;


