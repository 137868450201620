import React, { useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
// import { addRxPlugin, createRxDatabase  } from 'rxdb';
// import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
// import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';


/* Theme variables */
import { ROUTES } from './helper/constants';
import Empty from './pages/Empty';
import "./i18n";

import './theme/variables.css';
import { Preferences } from '@capacitor/preferences';
import { useTranslation } from 'react-i18next';
import Login from './pages/Auth/Login';
import Verify from './pages/Auth/Verify';
import Settings from './pages/Settings';
import Monitor from './pages/Monitor';
import Report from './pages/Reports';
import { db } from './db';
import { App as Application } from '@capacitor/app';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale);

setupIonicReact();

const App: React.FC = () => {
  const { i18n } = useTranslation();
  Application.addListener('resume', () => {
    Swal.fire({
      icon: 'warning',
      title: `<i class="text-danger">${t('Common.BeforeAlert')}</i>`,
      html: `<ol type="1" class="list-decimal">
                    <li>${t('Common.Alert1')}</li>
                    <li>${t('Common.Alert2')}</li>
                    <li>${t('Common.Alert3')}</li>
                    <li>${t('Common.Alert4')}</li>
                    <li>${t('Common.Alert5')}</li>
                    <li>${t('Common.Alert6')}</li>
                    <li>${t('Common.Alert7')}</li>
                    <li>${t('Common.Alert8')}</li>
                    </ol>`,
      heightAuto: false,
      backdrop: true,
      customClass: {
        container: 'alert-container w-30',
        title: 'alert-title',
      },
    });
  })
  useEffect(() => {
    const initLang = async () => {
      const res = await Preferences.get({ key: 'eilon.lang' });
      if (res && res.value)
        i18n.changeLanguage(res.value);
    }

    const dbConnect = () => {
      db.open().catch(function (error) {
        console.error('Error opening database: ' + error);
      });

      db.logs_proofftest.clear().then(function () {
        console.log('Table logs_proofftest emptied successfully');
      }).catch(function (error: any) {
        console.error('Error clearing table: ' + error);
      });
    }

    initLang();
    dbConnect();
  }, [])

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to={ROUTES.Monitor} />
            </Route>
            <Route path={ROUTES.Login} exact={true} render={() => <Login />} />
            <Route path={ROUTES.Verify} exact={true} render={() => <Verify />} />

            <Route path={ROUTES.Monitor} exact={true} render={() => <Monitor />} />
            <Route path={ROUTES.Settings} exact={true} render={() => <Settings />} />
            <Route path={ROUTES.Reports} exact={true} render={() => <Report />} />
            <Route path={ROUTES.ConnectDevice} exact={true} render={() => <Empty />} />
            <Route path={ROUTES.Totalizer} exact={true} render={() => <Empty />} />
            <Route path={ROUTES.Document} exact={true} render={() => <Empty />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
