import React, { FC, useEffect, useState } from "react";
import { IonIcon, IonToggle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { codeSlashSharp, cubeSharp, documentSharp, downloadSharp, mailSharp, trashSharp } from "ionicons/icons";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import CommonLayout from "../../Layout/CommonLayout";
import useAppData from "../../hooks/useAppData";
import Text from "../../components/Text";
import { IProject } from "../../helper/types";
import { db } from '../../db'
import Swal from "sweetalert2";
import Spinner from "../../components/Spinner";
import { format, getTime, getUnixTime } from "date-fns"
// eslint-disable-next-line
// @ts-ignore
import { EmailComposer } from "@awesome-cordova-plugins/email-composer"
import { renderToString } from 'react-dom/server'
import './index.css';

interface LogFilter {
  ok: boolean;
  overload: boolean;
  danger: boolean;
  underload: boolean;
  err: boolean;
  start: Date;
  end: Date;
}

const Report: FC = () => {
  const { platformType, projects, lcs, logs } = useAppData();
  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<IProject[]>([])
  const [selectedId, setSelectedId] = useState<string>('')
  const [filter, setFilter] = useState<LogFilter>({
    ok: false,
    overload: false,
    danger: false,
    underload: false,
    err: false,
    start: new Date(),
    end: new Date(),
  } as LogFilter);
  const [logList, setLogList] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // let filtered = logs.filter(item => item.project_id === selectedId)
    // setLogList(filtered)
    // if (filtered.length > 0) {
    //   filtered = filtered.filter(item => item.value && item.overload && item.value > item.overload)
    // }
    if (selectedId) {
      const { ok, overload, danger, underload, err, start, end } = filter;
      load_reports(selectedId, ok, overload, danger, underload, err, start, end);
    } else {
      setLogList(null)
    }
  }, [logs, selectedId, filter])

  // Helper function to merge and deduplicate logs
  const mergeAndDeduplicateLogs = (logArrays: any) => {
    const logMap = new Map();
    logArrays.forEach((logs: any) => {
      logs.forEach((log: any) => {
        if (!logMap.has(log.id)) {
          logMap.set(log.id, log);
        }
      });
    });
    return Array.from(logMap.values());
  }

  const load_reports = (project_id: any, ok: any, overload: any, danger: any, underload: any, trerr: any, fromVal: any, toVal: any) => {
    setLoading(true);

    const from = getTime(new Date(fromVal).setHours(0, 0, 0, 0))
    const to = getTime(new Date(toVal).setHours(23, 59, 59, 999));
    console.log('start', from, to, format(getTime(fromVal), 'yyyy-MM-dd'), format(getTime(toVal), 'yyyy-MM-dd'));

    // Define individual queries based on filters
    const queries = [];
    if (ok) {
      queries.push(db.logs.where('project_id').equals(project_id)
        .and(log => log.log_date >= from && log.log_date <= to && log.value >= log.underload && log.value <= log.overload).toArray());
    }
    if (overload) {
      queries.push(db.logs.where('project_id').equals(project_id)
        .and(log => log.log_date >= from && log.log_date <= to && log.value > log.overload).toArray());
    }
    if (danger) {
      queries.push(db.logs.where('project_id').equals(project_id)
        .and(log => log.log_date >= from && log.log_date <= to && log.value > log.overload * 1.3).toArray());
    }
    if (underload) {
      queries.push(db.logs.where('project_id').equals(project_id)
        .and(log => log.log_date >= from && log.log_date <= to && log.value < log.underload && log.value !== -99999999).toArray());
    }
    if (trerr) {
      queries.push(db.logs.where('project_id').equals(project_id)
        .and(log => log.log_date >= from && log.log_date <= to && log.value === -99999999).toArray());
    }

    // If no filters are selected, use a default query
    if (queries.length === 0) {
      queries.push(db.logs.where('project_id').equals(project_id)
        .and(log => log.log_date >= from && log.log_date <= to).toArray());
    }

    // Execute all queries and merge results
    Promise.all(queries)
      .then(results => mergeAndDeduplicateLogs(results))
      .then(mergedLogs => {
        console.log('has log', mergedLogs.length);
        if (mergedLogs.length > 0) {
          // document.getElementById('delete_selected_logs').disabled = false;
          const groups: any = {};
          mergedLogs.forEach(log => {
            const formattedDate = format(new Date(log.log_date), "yyyy-MM-dd pp");
            groups[formattedDate] = groups[formattedDate] || [];
            groups[formattedDate].push(log);
          });

          setLogList(groups)
        } else
          setLogList(null)

        setLoading(false)
      })
      .catch(error => {
        console.error('Error generating report: ' + error);
        setLoading(false)
      });
  }

  const ExportList = [
    { title: t('Report.Email'), icon: mailSharp },
    { title: t('Report.CSV'), icon: downloadSharp },
    { title: t('Report.JSON'), icon: codeSlashSharp },
    { title: t('Report.SQL'), icon: cubeSharp },
    { title: t('Report.PDF'), icon: documentSharp },
  ]

  const draw_report_table = () => {
    console.log("===draw_report_table===")
  }

  const ReportTable = () => {
    return (
      <div className="w-full pb-5">
        {!loading && logList && Object.keys(logList).map((key, index) => (
          <div key={index} className="my-2">
            <Text classes="flex bg-primary text-white py-1 px-3" label={key} />
            <table className="table-auto w-full border-collapse border border-slate-400">
              <thead>
                <tr className="text-left">
                  <th className="border border-slate-300 text-dark dark:text-white px-3 py-1 font-medium ">{t("Report.ID")}</th>
                  <th className="border border-slate-300 text-dark dark:text-white px-3 py-1 font-medium w-28">{t("Report.Load")}</th>
                  <th className="border border-slate-300 text-dark dark:text-white px-3 py-1 font-medium w-28">{t("Report.Battery")}</th>
                  <th className="border border-slate-300 text-dark dark:text-white px-3 py-1 font-medium w-48">{t("Report.Time")}</th>
                </tr>
              </thead>
              <tbody>
                {logList[key] && logList[key].length > 0 && logList[key].map((sItem: any, sKey: any) => {
                  const lc = lcs.find(cItem => cItem.id === sItem.lc_id.toString())
                  if (sKey === 1) {
                    console.log('lc info: ', lc, sItem, lcs)
                  }
                  return (
                    <tr key={sKey} className="w-full">
                      <td className="border border-slate-300 text-dark dark:text-white px-3 py-1">{lc?.title}</td>
                      <td className="border border-slate-300 text-dark dark:text-white px-3 py-1">{sItem.value} {sItem.unit}</td>
                      <td className="border border-slate-300 text-dark dark:text-white px-3 py-1">{sItem.battery}</td>
                      <td className="border border-slate-300 text-dark dark:text-white px-3 py-1">{sItem.log_date}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    )
  }

  useEffect(() => {
    setProjectList(projects)
    if (projects.length > 0) {
      setSelectedId(projects[0].id)
    }
  }, [projects])

  useEffect(() => {
    console.log('filter data: ', filter)
  }, [filter])

  // export data from filtered logs
  const handleExport = async (type: string) => {
    // if (logList) {
    //   let logData: any[] = [];
    //   Object.keys(logList).forEach((key) => {
    //     logData = [
    //       ...logData,
    //       ...logList[key],
    //     ]
    //   })
    //   console.log('export data list: ', logData)
    //   if (logData.length > 0) {
        switch (type) {
          case t('Report.Email'):
            // export email
            EmailComposer.open({
              subject: 'Ron Stage Master Report',
              attachments: ['base64:rsm.html//' + window.btoa(renderToString(<ReportTable />))]
            })
            break;
          case t('Report.CSV'): {
            let path = 'csvExport.csv';
            if (platformType === 'android')
              path = '../Download/csvExport.csv'
            const writeResult = await Filesystem.writeFile({
              path,
              data: "123,324,5223\n32,53,11",
              directory: Directory.Documents,
              encoding: Encoding.UTF8,
            })
            console.log("xml write result: ", writeResult);

            break;
          }
          case t('Report.JSON'): {
            console.log('json data action')
            let path = 'jsonExport.json';
            if (platformType === 'android')
              path = '../Download/jsonExport.json'
            const writeResult = await Filesystem.writeFile({
              path,
              data: JSON.stringify({ test: 'test title', value: 'test value' }),
              directory: Directory.Documents,
              encoding: Encoding.UTF8,
            })
            console.log("xml write result: ", writeResult);
            break;
          }
          case t('Report.SQL'):
            break;
          case t('Report.PDF'):
            break;
          default:
            break;
        }
    //   }
    // }
  }

  const handleChangeFilter = (field: string, value: boolean | Date) => {
    setFilter(v => ({ ...v, [field]: value }))
    console.log('date changed: ', field, value)
  }

  const handleRemove = () => {
    if (logList) {
      console.log('ahndlerem')
      Swal.fire({
        title: 'Delete Logs',
        text: 'Please confirm logs deletion. this process can not be undone',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("Common.Confirm"),
        cancelButtonText: t("Common.Cancel"),
        heightAuto: false
      }).then((result) => {
        // To get log ids to be deleted
        let logIds: any = [];
        Object.keys(logList).forEach((key) => {
          logIds = [
            ...logIds,
            ...logList[key].map((item: any) => item.id)
          ]
        })
        console.log('delet ids: ', logIds)
        // Delete logs from db
        if (result.value) {
          db.logs.bulkDelete(logIds).then(() => {
            console.log('Successfully deleted items');
            const { ok, overload, danger, underload, err, start, end } = filter;
            load_reports(selectedId, ok, overload, danger, underload, err, start, end);
          }).catch(error => {
            console.error('Failed to delete items:', error);
          });
        }
      });
    }
  }

  return (
    <CommonLayout>
      <div className="grid grid-cols-4 gap-2">
        <div className="flex flex-col gap-2">
          <Text classes="w-full bg-primary px-4 py-1" label={t('Report.MyProjects')} />
          <div className="flex flex-col">
            {projectList.length > 0 && projectList.map((item: IProject, index: number) => (
              <ul
                key={index}
                className="list-disc list-inside px-4 py-1 cursor-pointer"
                onClick={() => setSelectedId(item.id)}
              >
                <li>
                  <Text classes="text-primary" label={item.title} />
                </li>
              </ul>
            ))}
          </div>
        </div>
        <div className="col-span-3 flex flex-col px-6 gap-2">
          <div className="flex flex-row items-center gap-2 py-2">
            <Text label={`${t('Report.Export')}: `} />
            {ExportList.map((item, key) => (
              <div
                key={key}
                className="flex flex-row items-center gap-1"
                onClick={() => handleExport(item.title)}
              >
                <IonIcon src={item.icon} color="primary" />
                <Text label={item.title} />
              </div>
            ))}
          </div>
          <hr className="w-full border border-gray-300" />
          <div className="flex flex-col gap-2">
            <Text label={`${t('Common.Filter')}:`} />
            <div className="flex flex-row items-center gap-4">
              <div className='flex flex-row justify-center items-center gap-2' onClick={() => handleChangeFilter('ok', !filter.ok)}>
                <IonToggle checked={filter.ok} onChange={() => { console.log('') }} />
                <Text label={t('Common.Okay')} />
              </div>
              <div className='flex flex-row justify-center items-center gap-2' onClick={() => handleChangeFilter('overload', !filter.overload)}>
                <IonToggle checked={filter.overload} onChange={() => { console.log('') }} />
                <Text label={t('Common.Overload')} />
              </div>
              <div className='flex flex-row justify-center items-center gap-2' onClick={() => handleChangeFilter('danger', !filter.danger)}>
                <IonToggle checked={filter.danger} onChange={() => { console.log('') }} />
                <Text label={t('Common.Danger')} />
              </div>
              <div className='flex flex-row justify-center items-center gap-2' onClick={() => handleChangeFilter('underload', !filter.underload)}>
                <IonToggle checked={filter.underload} onChange={() => { console.log('') }} />
                <Text label={t('Common.Underload')} />
              </div>
              <div className='flex flex-row justify-center items-center gap-2' onClick={() => handleChangeFilter('err', !filter.err)}>
                <IonToggle checked={filter.err} onChange={() => { console.log('') }} />
                <Text label={t('Common.TrErr')} />
              </div>
            </div>
            <div className="flex flex-row items-center gap-8">
              <div className="flex flex-row items-center gap-2">
                <Text label={t('Common.From')} />
                <input
                  type="date"
                  value={format(new Date(filter.start).toISOString(), 'yyyy-MM-dd')}
                  className="outline-none border border-dark rounded p-1"
                  onChange={(e) => handleChangeFilter('start', new Date(getTime(e.target.value) + new Date().getTimezoneOffset() * 60 * 1000))}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Text label={t('Common.To')} />
                <input
                  type="date"
                  value={format(new Date(filter.end), 'yyyy-MM-dd')}
                  className="outline-none border border-dark rounded p-1"
                  onChange={(e) => handleChangeFilter('end', new Date(getTime(e.target.value) + new Date().getTimezoneOffset() * 60 * 1000))}
                />
              </div>
            </div>
          </div>
          <div
            className={`
            p-2 w-max rounded flex justify-center items-center"
            ${logList ? 'bg-danger' : 'bg-red-300'}
            `}
            onClick={() => handleRemove()}
          >
            <IonIcon icon={trashSharp} color="light" />
          </div>
          <Spinner visible={loading} />
          <ReportTable />
        </div>
      </div>
    </CommonLayout>
  )
}

export default Report;
