import React, { createContext, useState } from 'react'
import { IGroup, ILC, ILCAlert, ILog, IProject } from '../helper/types';
import { MonitorStatus } from '../helper/constants';
import { Capacitor } from '@capacitor/core';
import { db } from '../db';

interface IAppContext {
  mode: 'dark' | 'light';
  errStr: string;
  successStr: string;
  isMobile: boolean;
  platformType: string;

  monitorStatus: 'view' | 'list' | 'prog' | 'stop';
  projects: IProject[];
  curProject: IProject;
  lcs: ILC[];
  groups: IGroup[];
  logs: ILog[];
  visibleModal: string;
  bleConnected: boolean;
  batteryStatus: number;
  confirmed: boolean;
  totalWeightHtml: string;
  LCMax: boolean;
  warnList: any[];
  liveLC: any;
  weighData: any;
  lc_wind_alerts: ILCAlert[];
  lc_alerts: ILCAlert[];

  updateMode: (status: 'dark' | 'light') => void;
  updateErrStr: (str: string) => void;
  updateSuccessStr: (str: string) => void;

  init: () => void,
  updateMonitorStatus: () => void;
  updateProjects: (data: Partial<IProject> | IProject[]) => void,
  updateCurProject: (data: Partial<IProject>) => void,
  updateLCs: (data: ILC | ILC[]) => void,
  updateGroups: (data: IGroup | IGroup[]) => void,
  updateLogs: (data: ILog | ILog[]) => void,

  updateVisibleModal: (name: string) => void,
  updateBleConnected: (status: boolean) => void,
  updateBatteryStatus: (status: number) => void,
  updateConfirmed: (status: boolean) => void,
  updateTotalWeightHtml: (html: string) => void,
  updateLCMax: (status: boolean) => void,
  updateWarnList: (data: any | any[]) => void,
  updateLiveLC: (data: any) => void,
  updateWeighData: (data: any) => void,
  load_projects: () => Promise<void>,
  load_lcs: () => Promise<void>,
  load_groups: () => Promise<void>,
  updateLCWindAlerts: (data: ILCAlert | ILCAlert[]) => Promise<void>,
  updateLCAlerts: (data: ILCAlert | ILCAlert[]) => Promise<void>,
}


const initialState = {
  mode: 'dark',
  errStr: '',
  successStr: '',
  isMobile: false,
  platformType: false,
  monitorStatus: 'view',
  projects: [],
  curProject: {} as IProject,
  lcs: [],
  groups: [],
  logs: [],
  visibleModal: '',
  bleConnected: false,
  batteryStatus: 0,
  confirmed: false,
  totalWeightHtml: 'Tr.Err',
  LCMax: false,
  warnList: [],
  // TODO: Remove below liveLC value on Deploy.(Used for Test Purpose)
  // liveLC: {id: '301', realval: 35, zero: -35},
  liveLC: null,
  weighData: null,
  lc_wind_alerts: [],
  lc_alerts: [],

  // eslint-disable-next-line
  updateErrStr: () => { },
  // eslint-disable-next-line
  updateMode: () => { },
  // eslint-disable-next-line
  updateSuccessStr: () => { },
  // eslint-disable-next-line
  init: () => { },
  // eslint-disable-next-line
  updateMonitorStatus: () => { },
  // eslint-disable-next-line
  updateProjects: () => { },
  // eslint-disable-next-line
  updateCurProject: () => { },
  // eslint-disable-next-line
  updateLCs: () => { },
  // eslint-disable-next-line
  updateGroups: () => { },
  // eslint-disable-next-line
  updateLogs: () => { },
  // eslint-disable-next-line
  updateVisibleModal: () => { },
  // eslint-disable-next-line
  updateBleConnected: () => { },
  // eslint-disable-next-line
  updateBatteryStatus: () => { },
  // eslint-disable-next-line
  updateConfirmed: () => { },
  // eslint-disable-next-line
  updateTotalWeightHtml: () => { },
  // eslint-disable-next-line
  updateLCMax: () => { },
  // eslint-disable-next-line
  updateWarnList: () => { },
  // eslint-disable-next-line
  updateLiveLC: () => { },
  // eslint-disable-next-line
  updateWeighData: () => { },
  // eslint-disable-next-line
  load_projects: () => undefined,
  load_lcs: () => undefined,
  load_groups: () => undefined,
  updateLCWindAlerts: () => undefined,
  updateLCAlerts: () => undefined,
}

const AppContext = createContext({} as IAppContext)

// eslint-disable-next-line
export const AppDataProvider = (props: any) => {
  const { children } = props;
  const [mode, setMode] = useState<'dark' | 'light'>(props.mode || initialState.mode);
  const [errStr, setErrStr] = useState<string>(props.errStr || initialState.errStr);
  const [successStr, setSuccessStr] = useState<string>(props.successStr || initialState.successStr);
  const [monitorStatus, setMonitorStatus] = useState<'view' | 'list' | 'prog' | 'stop'>(props.monitorStatus || initialState.monitorStatus);
  const [projects, setProjects] = useState<IProject[]>(props.projects || initialState.projects);
  const [curProject, setCurProject] = useState<IProject>(props.curProject || initialState.curProject);
  const [lcs, setLCs] = useState<ILC[]>(props.lcs || initialState.lcs);
  const [groups, setGroups] = useState<IGroup[]>(props.groups || initialState.groups);
  const [logs, setLogs] = useState<ILog[]>(props.logs || initialState.logs);
  const [visibleModal, setVisibleModal] = useState<string>(props.visibleModal || initialState.visibleModal);
  const [bleConnected, setBleConnected] = useState<boolean>(props.bleConnected || initialState.bleConnected);
  const [batteryStatus, setBatteryStatus] = useState<number>(props.batteryStatus || initialState.batteryStatus);
  const [confirmed, setConfirmed] = useState<boolean>(props.confirmed || initialState.confirmed);
  const [totalWeightHtml, setTotalWeightHtml] = useState(props.totalWeightHtml || initialState.totalWeightHtml);
  const [LCMax, setLCMax] = useState(props.LCMax || initialState.LCMax);
  const [warnList, setWarnList] = useState(props.warnList || initialState.warnList);
  const [liveLC, setLiveLC] = useState(props.liveLC || initialState.liveLC);
  const [weighData, setWeighData] = useState(props.weighData || initialState.weighData);
  const [lc_wind_alerts, setLCWindAlerts] = useState<ILCAlert[]>(props.lc_wind_alerts || initialState.lc_wind_alerts);
  const [lc_alerts, setLCAlerts] = useState<ILCAlert[]>(props.lc_alerts || initialState.lc_alerts);

  const handleMode = (mode: 'dark' | 'light') => {
    setMode(mode)
  }

  const handleErrStr = (str: string) => {
    setErrStr(str)
  }

  const handleSuccessStr = (str: string) => {
    setSuccessStr(str)
  }

  const handleInit = () => {
    setLCs([])
  }

  const handleMonitorStatus = () => {
    const index = MonitorStatus.indexOf(monitorStatus)
    if (index === 3)
      setMonitorStatus(MonitorStatus[0])
    else
      setMonitorStatus(MonitorStatus[index + 1])
  }

  const handleProjects = (project: Partial<IProject> | IProject[]) => {
    if (Array.isArray(project)) {
      setProjects(project)
    } else {
      let ids: string[] = []
      if (projects.length > 0) ids = projects.map(item => item.id)

      if (project.id && ids.includes(project.id)) {
        setProjects(v => v.map(item => {
          if (item.id === project.id) return ({ ...item, ...project })
          else return item;
        }))
      } else {
        setProjects((prevProjects:any) => {
          const projectExists = prevProjects.some((p: { title: string | undefined; }) => p.title=== project.title);
          if (projectExists) {
            return prevProjects; // return the same array if the project already exists
          }
          return [...prevProjects, project];
        });  
        // setProjects(v => [...v, project as IProject])
      }
    }
  }

  const handleCurProject = (project: Partial<IProject>) => {
    setCurProject(v => ({ ...v, ...project }));
  }

  const handleLCs = (lc: ILC | ILC[]) => {
    let ids: string[] = []
    if (lcs.length > 0) ids = lcs.map(item => item.id)

    if (Array.isArray(lc)) {
      setLCs(lc)
    } else {
      console.log('handleLCs', { lc, ids, lcs })
      if (ids.includes(lc.id)) {
        setLCs(v => v.map(item => {
          if (item.id === lc.id) return ({ ...item, ...lc })
          else return item;
        }))
      } else {
        setLCs(v => [...v, lc])
      }
    }
  }

  const handleGroups = (group: IGroup | IGroup[]) => {
    let ids: string[] = []
    if (groups.length > 0) ids = groups.map(item => item.id)
    if (Array.isArray(group)) {
      setGroups(group)
    } else {
      if (ids.includes(group.id)) {
        setGroups(v => v.map(item => {
          if (item.id === group.id) return ({ ...item, ...group })
          else return item;
        }))
      } else {
        setGroups(v => [...v, group])
      }
    }
  }

  const handleLogs = (log: ILog | ILog[]) => {
    let ids: string[] = []
    if (lcs.length > 0) ids = lcs.map(item => item.id)

    if (Array.isArray(log)) {
      setLogs(log)
    } else {
      if (ids.includes(log.id)) {
        setLogs(v => v.map(item => {
          if (item.id === log.id) return ({ ...item, ...log })
          else return item;
        }))
      } else {
        setLogs(v => [...v, log])
      }
    }
  }

  const handleVisibleModal = (name: string) => {
    setVisibleModal(name)
  }

  const handleBleConnected = (status: boolean) => {
    setBleConnected(status)
  }

  const handleBatteryStatus = (status: number) => {
    setBatteryStatus(status)
  }

  const handleConfirmed = (status: boolean) => {
    setConfirmed(status)
  }

  const handleTotalWeightHtml = (html: string) => {
    setTotalWeightHtml(html)
  }

  const handleLCMax = (status: boolean) => {
    setLCMax(status)
  }

  const handleWarnList = (data: any | any[]) => {
    if (Array.isArray(data)) {
      setWarnList(data)
    } else {
      setWarnList((v: any) => [...v, data])
    }
  }

  const handleLiveLC = (data: any) => {
    setLiveLC(data);
  }

  const handleWeighData = (data: any) => {
    setWeighData(data);
  }

  const isMobile = Capacitor.isNativePlatform();
  const platformType = Capacitor.getPlatform();


  const load_projects = async () => {
    const projectData = await db.projects.toArray();
    if (projectData.length > 0) {
      handleProjects(projectData)
      handleCurProject(projectData[0])
    }
  }

  const load_lcs = async () => {
    if (!curProject.id) return
    const myLcsStore = db.lcs;
    const key = 'project_id';
    const value = curProject.id;
    const _lcs: any = [];
    myLcsStore.where(key).equals(value).toArray().then(function (data) {
      data.forEach(function (item) {
        _lcs.push(item)
      });
      handleLCs(_lcs);
    }).catch(function (error) {
      console.error('Error querying data from the table: ' + error);
    });
  }

  const load_groups = async () => {
    if (!curProject.id) return
    const myGroups = db.groups;
    const key = 'project_id';
    const value = parseInt(curProject.id);
    myGroups.where(key).equals(value).toArray().then(function (data) {
      handleGroups(data)
    }).catch(function (error) {
      console.error('Error querying data from the table: ' + error);
    });
  }

  const handleLCWindAlerts = async (data: ILCAlert | ILCAlert[]) => {
    if (Array.isArray(data)) {
      setLCWindAlerts(data)
    } else {
      setLCWindAlerts(v => [...v, data])
    }
  }

  const handleLCAlerts = async (data: ILCAlert | ILCAlert[]) => {
    if (Array.isArray(data)) {
      setLCAlerts(data)
    } else {
      setLCAlerts(v => [...v, data])
    }
  }

  return (
    <AppContext.Provider
      value={{
        mode,
        errStr,
        successStr,
        isMobile,
        platformType,
        monitorStatus,
        projects,
        curProject,
        lcs,
        groups,
        logs,
        visibleModal,
        bleConnected,
        batteryStatus,
        confirmed,
        totalWeightHtml,
        LCMax,
        warnList,
        liveLC,
        weighData,
        lc_wind_alerts,
        lc_alerts,

        updateMode: handleMode,
        updateErrStr: handleErrStr,
        updateSuccessStr: handleSuccessStr,
        init: handleInit,
        updateMonitorStatus: handleMonitorStatus,
        updateProjects: handleProjects,
        updateCurProject: handleCurProject,
        updateLCs: handleLCs,
        updateGroups: handleGroups,
        updateVisibleModal: handleVisibleModal,
        updateBleConnected: handleBleConnected,
        updateBatteryStatus: handleBatteryStatus,
        updateConfirmed: handleConfirmed,
        updateLogs: handleLogs,
        updateTotalWeightHtml: handleTotalWeightHtml,
        updateLCMax: handleLCMax,
        updateWarnList: handleWarnList,
        updateLiveLC: handleLiveLC,
        updateWeighData: handleWeighData,
        load_projects,
        load_lcs,
        load_groups,
        updateLCWindAlerts: handleLCWindAlerts,
        updateLCAlerts: handleLCAlerts,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
