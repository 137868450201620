// db.ts
import Dexie, { Table } from 'dexie';

export class MySubClassedDexie extends Dexie {

  projects!: Table<any>;
  lcs!: Table<any>;
  groups!: Table<any>;
  logs!: Table<any>;
  project_details!: Table<any>;
  logs_proofftest!: Table<any>;
  totalizer!: Table<any>;

  constructor() {
    super('rsm');
    this.version(14).stores({
      projects: '++id, title, units, pre_overload, total_overload, reports, cycle, last_settings_change, stage_x, stage_y, p_image, show_graphs, windmeter_units',
      lcs: '++lc_id, id, project_id, title, psw, underload, overload, total_sum, groups, view_x, view_y, calibration_offset, zero, tare, logoheaderpdf, certnumber, certcompany, certcompanyaddress, companytel,companycontact, productdescription, serialortag, wll, testmethod, loadtestto, notes, machinecode, productname, modelname, testlocation',
      groups: '++group_id, id, project_id, title, overload, tare',
      logs: '++id, lc_id, project_id, log_date, value, overload, underload, unit, realval, battery, [log_date]',
      project_details: '++id, project_id, logoheaderpdf, certnumber, certcompany, certcompanyaddress, companytel, companycontact, productdescription, serialortag, wll, testmethod, loadtestto, notes, machinecode, productname, modelname, testlocation',
      logs_proofftest: '++id, group_id, lc_id, project_id, log_date, value, overload, underload, unit, realval',
      totalizer: '++id, group_id, project_id, sum, log_date'
    });
  }
}

export const db = new MySubClassedDexie();