import React, { FC, useEffect, useState } from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import Text from '../Text';
import { IProject } from '../../helper/types';
import TextInput from '../TextInput';
import useAppData from '../../hooks/useAppData';

interface ProjectListModalProps {
  visible: boolean;
  data: IProject[];
  onSelect: (id: string) => void;
  onAction: () => void;
  onClose: () => void;
}

const ProjectListModal: FC<ProjectListModalProps> = props => {
  const {
    visible,
    data,
    onSelect,
    onAction,
    onClose,
  } = props;
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>('');
  const [filteredList, setFilteredList] = useState<IProject[]>([])
  const { isMobile } = useAppData()

  useEffect(() => {
    if (filter)
      setFilteredList(data.filter(item => item.title && item.title.toLowerCase().includes(filter.toLowerCase())))
    else
      setFilteredList(data)

  }, [data, filter])

  return (
    <Modal
      visible={visible}
      header={t("Project.ProjectList")}
      classes={` w-80 border border-secondary rounded-md ${isMobile ? ' overflow-auto max-h-[324px]' : ''}`}
      okTitle={t("Project.NewProjectTitle")}
      contentClasses='!items-center py-2 px-6'
      footerClasses={`!justify-center ${isMobile ? '' : 'py-2'} `}
      onAction={() => onAction()}
      onClose={() => onClose()}
    >
      <TextInput
        placeholder='Search'
        classes='w-full'
        value={filter}
        onChange={e => setFilter(e.target.value)}
      />
      {filteredList.length > 0 && <div className='flex flex-col py-4 gap-2 w-full'>
        {filteredList.map((item: IProject, index: number) => (
          <div
            key={index}
            className='w-full px-3 border-b border-medium cursor-pointer'
            onClick={() => onSelect(item.id)}
          >
            <Text label={item.title} />
          </div>
        ))}
      </div>}
    </Modal>
  )
}

export default ProjectListModal;
