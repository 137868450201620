import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

// import { useLocation } from 'react-router-dom';
import {
  barChartOutline,
  barbellOutline,
  bluetoothOutline,
  briefcaseOutline,
  calculatorOutline,
  carOutline,
  cogOutline,
  moonOutline,
  sunnyOutline,
  timerOutline,
  tvOutline,
} from 'ionicons/icons';
import { MENUS, ModalMenus, ModalNames, ROUTES } from '../helper/constants';

import { useTranslation } from 'react-i18next';
import { Preferences } from '@capacitor/preferences';

import lightLogo from '../assets/images/logo-lightmode.webp';
import darkLogo from '../assets/images/logo-darkmode.webp';
import './Menu.css';
import { useHistory, useLocation } from 'react-router';
import useAppData from '../hooks/useAppData';
import { connectMenuIcon, monitorMenuIcon, projectsMenuIcon, prooftesMenuIcon, reportsMenuIcon, settingsMenuIcon, totalizerMenuIcon, weighingMenuIcon } from '../assets/icons';
import Swal from 'sweetalert2';
// import darkLogo from '../assets/images/logo-darkmode.webp';
import { db } from '../db';


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  imgIcon: string;
}

const appPages: AppPage[] = [
  {
    title: MENUS.Monitor,
    url: ROUTES.Monitor,
    iosIcon: timerOutline,
    mdIcon: timerOutline,
    imgIcon: monitorMenuIcon,
  },
  {
    title: MENUS.Projects,
    url: ROUTES.Projects,
    iosIcon: briefcaseOutline,
    mdIcon: briefcaseOutline,
    imgIcon: projectsMenuIcon,
  },
  {
    title: MENUS.Settings,
    url: ROUTES.Settings,
    iosIcon: cogOutline,
    mdIcon: cogOutline,
    imgIcon: settingsMenuIcon,
  },
  {
    title: MENUS.Reports,
    url: ROUTES.Reports,
    iosIcon: barChartOutline,
    mdIcon: barChartOutline,
    imgIcon: reportsMenuIcon,
  },
  {
    title: MENUS.ConnectDevice,
    url: ROUTES.ConnectDevice,
    iosIcon: bluetoothOutline,
    mdIcon: bluetoothOutline,
    imgIcon: connectMenuIcon,
  },
  {
    title: MENUS.ProofTest,
    url: ROUTES.ProofTest,
    iosIcon: barbellOutline,
    mdIcon: barbellOutline,
    imgIcon: prooftesMenuIcon,
  },
  {
    title: MENUS.Totalizer,
    url: ROUTES.Totalizer,
    iosIcon: calculatorOutline,
    mdIcon: calculatorOutline,
    imgIcon: totalizerMenuIcon,
  },
  {
    title: MENUS.Document,
    url: ROUTES.Document,
    iosIcon: carOutline,
    mdIcon: carOutline,
    imgIcon: weighingMenuIcon,
  }
];

const Menu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { curProject, lcs, groups, mode, updateVisibleModal, updateErrStr, updateMode } = useAppData()

  const [lang, setLang] = useState<string>('')
  const [themeToggle, setThemeToggle] = useState(false);

  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    console.log('prefersDark: ', prefersDark, prefersDark.matches)
    initializeDarkTheme(prefersDark.matches);

    const initLang = async () => {
      const res = await Preferences.get({ key: 'eilon.lang' });
      let defaultLang = "en";
      if (res && res.value)
        defaultLang = res.value;
      setLang(defaultLang);
    }

    initLang();
  }, [])

  // Add or remove the "dark" class on the document body
  const toggleDarkTheme = (shouldAdd: boolean) => {
    document.body.classList.toggle('dark', shouldAdd);
  };

  // Check/uncheck the toggle and update the theme based on isDark
  const initializeDarkTheme = (isDark: boolean) => {
    console.log('isDark: ', isDark)
    setThemeToggle(isDark);
    toggleDarkTheme(isDark);
  }

  const handleChangeLang = async (langVal: string) => {
    i18n.changeLanguage(langVal);
    setLang(langVal);
    await Preferences.remove({ key: 'eilon.lang' });
    await Preferences.set({ key: 'eilon.lang', value: langVal })
  }

  const handleChangeMode = () => {
    if (mode === 'dark')
      updateMode('light')
    else
      updateMode('dark')
    initializeDarkTheme(!themeToggle)
  }

  const handleOpenMonitor = () => {
    if (curProject) {
      const filtredGroup = groups.filter(({ overload }) => overload);
      const groupZero = filtredGroup.find(({ overload }) => parseInt(overload) === 0)

      if (groupZero !== undefined) {
        Swal.fire({
          icon: 'error',
          title: `<h2 class="text-danger">${t('Common.Error')}</h2>`,
          html: `<p>${groupZero.title} Can't be 0</p>`,
          heightAuto: false
        });
        return;
      }
      const setGroups = groups?.filter((group) => group.project_id === curProject.id);
      const filtered = lcs.filter(item => item.total_sum)
      if (filtered.length > 0){
        if(setGroups.length > 0)
          history.push(ROUTES.Monitor)
        else
        updateErrStr(t('You Must First set at Least One Group'))
      }
      else
        updateErrStr(t('Msg.ErrInvalidMonitor'))
    } else {
      updateVisibleModal(ModalNames.NewProject)
    }

  }

  // reference: handleOpenMonitor()
  const draw_realtime_report = (date: any, data: any) => {
    console.log('===draw_realtime_report===')
    data.map((row: any) => (
      <>
        <tr data-stats={(row.value >= row.overload) ? "overload" : (row.value <= row.underload) ? "unedrload" : "ok"}>
          <td>{row.lc_id}</td>
          <td>{(row.value >= row.overload) ? "overload" : (row.value <= row.underload) ? "unedrload" : ""}</td>
          <td className={(row.value > row.overload || row.value < row.underload) ? "bg-danger" : ""}>{row.value}</td>
          {/* <td>{moment.unix(row.log_date / 1000).format("YYYY-MM-DDTHH:mm")}</td> */}
        </tr >
      </>
    ))
    // $("#warning_log_table").append(log_e);
  }

  const get_peak = () => {
    console.log('===get_peak===')
    if (curProject === null) {
      return;
    }

    const myLogsStore = db.logs;
    const key = 'project_id';
    const value = curProject.id; // Replace this with the value you are looking fo
    try {
      myLogsStore.where(key).equals(value).reverse().first().then(function (data: any) {
        const peak = data || 0;
      })
    } catch (error) {
      console.error('Error querying data from the table: ' + error);
    }
  }

  const ionIcon = true;

  return (
    <>
      <IonMenu contentId="main" type="overlay">
        <IonContent className='main-content' >
          <IonList id="inbox-list" className='bg-transparent dark:bg-dark'>
            <IonImg src={themeToggle ? darkLogo : lightLogo} alt='logo'></IonImg>
            <div className='flex flex-row items-center justify-between px-3 mb-4 gap-6'>
              <IonSelect
                value={lang}
                color='light'
                onIonChange={(e) => handleChangeLang(e.detail.value)}
              >
                <IonLabel color='dark' slot="label">
                  {t("Language")}
                </IonLabel>
                <IonSelectOption value="en">English</IonSelectOption>
                <IonSelectOption value="jp">日本語</IonSelectOption>
              </IonSelect>
              {/* { mode !== 'dark' ? */}
              {themeToggle ?
                <IonIcon src={moonOutline} color='dark' className='text-xl cursor-pointer' onClick={() => handleChangeMode()} />
                :
                <IonIcon src={sunnyOutline} className='text-xl cursor-pointer' onClick={() => handleChangeMode()} />
              }
            </div>
            {appPages.map((appPage, index) => {
              if (appPage.title === '')
                return <></>
              else if (ModalMenus.includes(appPage.title)) {
                if (appPage.title === MENUS.Monitor) {
                  return (
                    <IonMenuToggle key={index} autoHide={false} onClick={() => handleOpenMonitor()}>
                      <IonItem className='bg-white dark:bg-dark cursor-pointer' routerDirection="none" lines="none" detail={false}>
                        {ionIcon ? <IonIcon color={location.pathname === appPage.url ? 'primary' : 'dark'} aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          : <IonImg src={appPage.imgIcon} alt='' className='w-10 mr-2' />}
                        <IonLabel color={location.pathname === appPage.url ? 'primary' : 'dark'}>{t(`Menu.${appPage.title}`)}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                } else
                  return (
                    <IonMenuToggle key={index} className='bg-white dark:bg-dark' autoHide={false} onClick={() => updateVisibleModal(appPage.title)}>
                      <IonItem className='bg-white dark:bg-dark cursor-pointer' lines="none" detail={false}>
                        {ionIcon ? <IonIcon color={location.pathname === appPage.url ? 'primary' : 'dark'} aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          : <IonImg src={appPage.imgIcon} alt='' className='w-10 mr-2' />}
                        <IonLabel color={location.pathname === appPage.url ? 'primary' : 'dark'}>{t(`Menu.${appPage.title}`)}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  )
              } else
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className='bg-white dark:bg-dark' routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      {ionIcon ? <IonIcon color={location.pathname === appPage.url ? 'primary' : 'dark'} aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                        : <IonImg src={appPage.imgIcon} alt='' className='w-10 mr-2' />}
                      <IonLabel color={location.pathname === appPage.url ? 'primary' : 'dark'}>{t(`Menu.${appPage.title}`)}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
            })}
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default Menu;
