import React, { FC } from 'react';
import { ILC } from '../../helper/types';
import Text from '../Text';

interface MonitorStopProps {
  data: ILC[],
  unit: string;
  max: boolean;
}

const MonitorStop: FC<MonitorStopProps> = props => {
  const { data, unit, max } = props

  return (
    <div
      className={`
        grid
        ${data.length === 1 && 'grid-cols-1'}
        ${data.length === 2 && 'grid-cols-2'}
        ${data.length === 3 && 'grid-cols-3'}
        ${data.length >= 4 && 'grid-cols-4'}
      `}
    >
      {data.length > 0 && data.map((item: ILC, index: number) => (
        <div
          key={index}
          className={`
            flex flex-col items-center w-full border border-gray-500 py-2 gap-2 h-max
            ${max ? 'bg-cyan2' : 'bg-gray-400'}
          `}
        >
          <Text type='lg-dark' classes='font-medium leading-4' label={item.title} />
          <Text
            type='white'
            classes={`font-medium leading-4 ${!item.value ? 'bg-danger rounded-bold p-1' : '!text-5xl !text-dark'}`}
            label={max ? (item.max??'Tr.Err') : (item.value??'Tr.Err')}
          />
        </div>
      ))}
    </div>
  )
}

export default MonitorStop;
