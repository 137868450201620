import { IGroup, ILC } from "../types"
import { db } from '../../db'

// Project:  project.id
// Group:    group.group_id
// LCs:      lc.lc_id

export const setGroupTare = async (group_id: string, status: string) => {
  console.log('@db setGroupTare - group_id: ', group_id)
  return await db.groups.where('id').equals(group_id)
    .modify({ tare: status })
}

export const setGroupZero = async (project_id: string, group_id: string) => {
  console.log('@db setGroupZero')
  const projectLCs: ILC[] = await db.lcs.where('project_id').equals(project_id).toArray();
  const updates = projectLCs.filter(lc => lc.groups?.split(',').includes(group_id))
    .map(item => ({
      key: item.lc_id,
      changes: {
        psw: '0',
        zero: parseInt(item.value || '0') * -1,
      }
    }))

  
  return await db.lcs.bulkUpdate(updates)
}
